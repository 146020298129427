// source: authmanager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.ImgPro.AuthClientRequest', null, global);
goog.exportSymbol('proto.ImgPro.AuthClientResponse', null, global);
goog.exportSymbol('proto.ImgPro.AuthErrorCode', null, global);
goog.exportSymbol('proto.ImgPro.AuthType', null, global);
goog.exportSymbol('proto.ImgPro.AuthUserRequest', null, global);
goog.exportSymbol('proto.ImgPro.AuthUserResponse', null, global);
goog.exportSymbol('proto.ImgPro.BooleanType', null, global);
goog.exportSymbol('proto.ImgPro.ChangeUserPasswordRequest', null, global);
goog.exportSymbol('proto.ImgPro.ChangeUserPasswordResponse', null, global);
goog.exportSymbol('proto.ImgPro.ExitRequest', null, global);
goog.exportSymbol('proto.ImgPro.ExitResponse', null, global);
goog.exportSymbol('proto.ImgPro.ModifyUserRequest', null, global);
goog.exportSymbol('proto.ImgPro.ModifyUserResponse', null, global);
goog.exportSymbol('proto.ImgPro.RegisterUserRequest', null, global);
goog.exportSymbol('proto.ImgPro.RegisterUserResponse', null, global);
goog.exportSymbol('proto.ImgPro.SubscribeType', null, global);
goog.exportSymbol('proto.ImgPro.UserInfo', null, global);
goog.exportSymbol('proto.ImgPro.UserSubscribeRequest', null, global);
goog.exportSymbol('proto.ImgPro.UserSubscribeResponse', null, global);
goog.exportSymbol('proto.ImgPro.UserType', null, global);
goog.exportSymbol('proto.ImgPro.VerifyType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.UserInfo.displayName = 'proto.ImgPro.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.AuthClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.AuthClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.AuthClientRequest.displayName = 'proto.ImgPro.AuthClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.AuthClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.AuthClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.AuthClientResponse.displayName = 'proto.ImgPro.AuthClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.RegisterUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.RegisterUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.RegisterUserRequest.displayName = 'proto.ImgPro.RegisterUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.RegisterUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.RegisterUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.RegisterUserResponse.displayName = 'proto.ImgPro.RegisterUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.AuthUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.AuthUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.AuthUserRequest.displayName = 'proto.ImgPro.AuthUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.AuthUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.AuthUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.AuthUserResponse.displayName = 'proto.ImgPro.AuthUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.ModifyUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.ModifyUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.ModifyUserRequest.displayName = 'proto.ImgPro.ModifyUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.ModifyUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.ModifyUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.ModifyUserResponse.displayName = 'proto.ImgPro.ModifyUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.ChangeUserPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.ChangeUserPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.ChangeUserPasswordRequest.displayName = 'proto.ImgPro.ChangeUserPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.ChangeUserPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.ChangeUserPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.ChangeUserPasswordResponse.displayName = 'proto.ImgPro.ChangeUserPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.UserSubscribeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.UserSubscribeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.UserSubscribeRequest.displayName = 'proto.ImgPro.UserSubscribeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.UserSubscribeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.UserSubscribeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.UserSubscribeResponse.displayName = 'proto.ImgPro.UserSubscribeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.ExitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.ExitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.ExitRequest.displayName = 'proto.ImgPro.ExitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.ExitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.ExitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.ExitResponse.displayName = 'proto.ImgPro.ExitResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userUsable: jspb.Message.getFieldWithDefault(msg, 5, 0),
    verifyAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    vefifyType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 9, ""),
    email: jspb.Message.getFieldWithDefault(msg, 10, ""),
    country: jspb.Message.getFieldWithDefault(msg, 11, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 12, ""),
    subscribeFrom: jspb.Message.getFieldWithDefault(msg, 13, 0),
    subscribeTo: jspb.Message.getFieldWithDefault(msg, 14, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 16, 0),
    lastLoginIp: jspb.Message.getFieldWithDefault(msg, 17, ""),
    toNotify: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.UserInfo}
 */
proto.ImgPro.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.UserInfo;
  return proto.ImgPro.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.UserInfo}
 */
proto.ImgPro.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {!proto.ImgPro.UserType} */ (reader.readEnum());
      msg.setUserType(value);
      break;
    case 5:
      var value = /** @type {!proto.ImgPro.BooleanType} */ (reader.readEnum());
      msg.setUserUsable(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVerifyAt(value);
      break;
    case 7:
      var value = /** @type {!proto.ImgPro.VerifyType} */ (reader.readEnum());
      msg.setVefifyType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscribeFrom(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscribeTo(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastLoginIp(value);
      break;
    case 18:
      var value = /** @type {!proto.ImgPro.BooleanType} */ (reader.readEnum());
      msg.setToNotify(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUserUsable();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getVerifyAt();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getVefifyType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSubscribeFrom();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getSubscribeTo();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = message.getLastLoginIp();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getToNotify();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
};


/**
 * optional uint64 user_id = 1;
 * @return {number}
 */
proto.ImgPro.UserInfo.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.ImgPro.UserInfo.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.ImgPro.UserInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.UserInfo.prototype.setUsername = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.ImgPro.UserInfo.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.ImgPro.UserInfo.prototype.setPassword = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UserType user_type = 4;
 * @return {!proto.ImgPro.UserType}
 */
proto.ImgPro.UserInfo.prototype.getUserType = function() {
  return /** @type {!proto.ImgPro.UserType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.ImgPro.UserType} value */
proto.ImgPro.UserInfo.prototype.setUserType = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional BooleanType user_usable = 5;
 * @return {!proto.ImgPro.BooleanType}
 */
proto.ImgPro.UserInfo.prototype.getUserUsable = function() {
  return /** @type {!proto.ImgPro.BooleanType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.ImgPro.BooleanType} value */
proto.ImgPro.UserInfo.prototype.setUserUsable = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional uint64 verify_at = 6;
 * @return {number}
 */
proto.ImgPro.UserInfo.prototype.getVerifyAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.ImgPro.UserInfo.prototype.setVerifyAt = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional VerifyType vefify_type = 7;
 * @return {!proto.ImgPro.VerifyType}
 */
proto.ImgPro.UserInfo.prototype.getVefifyType = function() {
  return /** @type {!proto.ImgPro.VerifyType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.ImgPro.VerifyType} value */
proto.ImgPro.UserInfo.prototype.setVefifyType = function(value) {
  jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.ImgPro.UserInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.ImgPro.UserInfo.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phone = 9;
 * @return {string}
 */
proto.ImgPro.UserInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.ImgPro.UserInfo.prototype.setPhone = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string email = 10;
 * @return {string}
 */
proto.ImgPro.UserInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.ImgPro.UserInfo.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string country = 11;
 * @return {string}
 */
proto.ImgPro.UserInfo.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.ImgPro.UserInfo.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string comment = 12;
 * @return {string}
 */
proto.ImgPro.UserInfo.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.ImgPro.UserInfo.prototype.setComment = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint64 subscribe_from = 13;
 * @return {number}
 */
proto.ImgPro.UserInfo.prototype.getSubscribeFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.ImgPro.UserInfo.prototype.setSubscribeFrom = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint64 subscribe_to = 14;
 * @return {number}
 */
proto.ImgPro.UserInfo.prototype.getSubscribeTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.ImgPro.UserInfo.prototype.setSubscribeTo = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint64 created_at = 15;
 * @return {number}
 */
proto.ImgPro.UserInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.ImgPro.UserInfo.prototype.setCreatedAt = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint64 updated_at = 16;
 * @return {number}
 */
proto.ImgPro.UserInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.ImgPro.UserInfo.prototype.setUpdatedAt = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string last_login_ip = 17;
 * @return {string}
 */
proto.ImgPro.UserInfo.prototype.getLastLoginIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.ImgPro.UserInfo.prototype.setLastLoginIp = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional BooleanType to_notify = 18;
 * @return {!proto.ImgPro.BooleanType}
 */
proto.ImgPro.UserInfo.prototype.getToNotify = function() {
  return /** @type {!proto.ImgPro.BooleanType} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {!proto.ImgPro.BooleanType} value */
proto.ImgPro.UserInfo.prototype.setToNotify = function(value) {
  jspb.Message.setProto3EnumField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.AuthClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.AuthClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.AuthClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.AuthClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dynamicSecret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.AuthClientRequest}
 */
proto.ImgPro.AuthClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.AuthClientRequest;
  return proto.ImgPro.AuthClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.AuthClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.AuthClientRequest}
 */
proto.ImgPro.AuthClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDynamicSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.AuthClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.AuthClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.AuthClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.AuthClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDynamicSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.ImgPro.AuthClientRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.ImgPro.AuthClientRequest.prototype.setClientId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dynamic_secret = 2;
 * @return {string}
 */
proto.ImgPro.AuthClientRequest.prototype.getDynamicSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.AuthClientRequest.prototype.setDynamicSecret = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.AuthClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.AuthClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.AuthClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.AuthClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientExpiresIn: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.AuthClientResponse}
 */
proto.ImgPro.AuthClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.AuthClientResponse;
  return proto.ImgPro.AuthClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.AuthClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.AuthClientResponse}
 */
proto.ImgPro.AuthClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.AuthErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClientExpiresIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.AuthClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.AuthClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.AuthClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.AuthClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientExpiresIn();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional AuthErrorCode error_code = 1;
 * @return {!proto.ImgPro.AuthErrorCode}
 */
proto.ImgPro.AuthClientResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.ImgPro.AuthErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.AuthErrorCode} value */
proto.ImgPro.AuthClientResponse.prototype.setErrorCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ImgPro.AuthClientResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.AuthClientResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_token = 3;
 * @return {string}
 */
proto.ImgPro.AuthClientResponse.prototype.getClientToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.ImgPro.AuthClientResponse.prototype.setClientToken = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 client_expires_in = 4;
 * @return {number}
 */
proto.ImgPro.AuthClientResponse.prototype.getClientExpiresIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.ImgPro.AuthClientResponse.prototype.setClientExpiresIn = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.RegisterUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.RegisterUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.RegisterUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.RegisterUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userInfo: (f = msg.getUserInfo()) && proto.ImgPro.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.RegisterUserRequest}
 */
proto.ImgPro.RegisterUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.RegisterUserRequest;
  return proto.ImgPro.RegisterUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.RegisterUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.RegisterUserRequest}
 */
proto.ImgPro.RegisterUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ImgPro.UserInfo;
      reader.readMessage(value,proto.ImgPro.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.RegisterUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.RegisterUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.RegisterUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.RegisterUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ImgPro.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserInfo user_info = 1;
 * @return {?proto.ImgPro.UserInfo}
 */
proto.ImgPro.RegisterUserRequest.prototype.getUserInfo = function() {
  return /** @type{?proto.ImgPro.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.ImgPro.UserInfo, 1));
};


/** @param {?proto.ImgPro.UserInfo|undefined} value */
proto.ImgPro.RegisterUserRequest.prototype.setUserInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.ImgPro.RegisterUserRequest.prototype.clearUserInfo = function() {
  this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ImgPro.RegisterUserRequest.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.RegisterUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.RegisterUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.RegisterUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.RegisterUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userInfo: (f = msg.getUserInfo()) && proto.ImgPro.UserInfo.toObject(includeInstance, f),
    userToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userExpiresIn: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.RegisterUserResponse}
 */
proto.ImgPro.RegisterUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.RegisterUserResponse;
  return proto.ImgPro.RegisterUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.RegisterUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.RegisterUserResponse}
 */
proto.ImgPro.RegisterUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.AuthErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.ImgPro.UserInfo;
      reader.readMessage(value,proto.ImgPro.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserExpiresIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.RegisterUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.RegisterUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.RegisterUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.RegisterUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ImgPro.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getUserToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserExpiresIn();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional AuthErrorCode error_code = 1;
 * @return {!proto.ImgPro.AuthErrorCode}
 */
proto.ImgPro.RegisterUserResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.ImgPro.AuthErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.AuthErrorCode} value */
proto.ImgPro.RegisterUserResponse.prototype.setErrorCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ImgPro.RegisterUserResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.RegisterUserResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserInfo user_info = 3;
 * @return {?proto.ImgPro.UserInfo}
 */
proto.ImgPro.RegisterUserResponse.prototype.getUserInfo = function() {
  return /** @type{?proto.ImgPro.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.ImgPro.UserInfo, 3));
};


/** @param {?proto.ImgPro.UserInfo|undefined} value */
proto.ImgPro.RegisterUserResponse.prototype.setUserInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.ImgPro.RegisterUserResponse.prototype.clearUserInfo = function() {
  this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ImgPro.RegisterUserResponse.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string user_token = 4;
 * @return {string}
 */
proto.ImgPro.RegisterUserResponse.prototype.getUserToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.ImgPro.RegisterUserResponse.prototype.setUserToken = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 user_expires_in = 5;
 * @return {number}
 */
proto.ImgPro.RegisterUserResponse.prototype.getUserExpiresIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.ImgPro.RegisterUserResponse.prototype.setUserExpiresIn = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.AuthUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.AuthUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.AuthUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.AuthUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usernameOrEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    code: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.AuthUserRequest}
 */
proto.ImgPro.AuthUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.AuthUserRequest;
  return proto.ImgPro.AuthUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.AuthUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.AuthUserRequest}
 */
proto.ImgPro.AuthUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.AuthType} */ (reader.readEnum());
      msg.setAuthType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsernameOrEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.AuthUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.AuthUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.AuthUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.AuthUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUsernameOrEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional AuthType auth_type = 1;
 * @return {!proto.ImgPro.AuthType}
 */
proto.ImgPro.AuthUserRequest.prototype.getAuthType = function() {
  return /** @type {!proto.ImgPro.AuthType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.AuthType} value */
proto.ImgPro.AuthUserRequest.prototype.setAuthType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string username_or_email = 2;
 * @return {string}
 */
proto.ImgPro.AuthUserRequest.prototype.getUsernameOrEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.AuthUserRequest.prototype.setUsernameOrEmail = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.ImgPro.AuthUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.ImgPro.AuthUserRequest.prototype.setPassword = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string code = 4;
 * @return {string}
 */
proto.ImgPro.AuthUserRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.ImgPro.AuthUserRequest.prototype.setCode = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.AuthUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.AuthUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.AuthUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.AuthUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userInfo: (f = msg.getUserInfo()) && proto.ImgPro.UserInfo.toObject(includeInstance, f),
    userToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userExpiresIn: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.AuthUserResponse}
 */
proto.ImgPro.AuthUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.AuthUserResponse;
  return proto.ImgPro.AuthUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.AuthUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.AuthUserResponse}
 */
proto.ImgPro.AuthUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.AuthErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.ImgPro.UserInfo;
      reader.readMessage(value,proto.ImgPro.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserExpiresIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.AuthUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.AuthUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.AuthUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.AuthUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ImgPro.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getUserToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserExpiresIn();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional AuthErrorCode error_code = 1;
 * @return {!proto.ImgPro.AuthErrorCode}
 */
proto.ImgPro.AuthUserResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.ImgPro.AuthErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.AuthErrorCode} value */
proto.ImgPro.AuthUserResponse.prototype.setErrorCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ImgPro.AuthUserResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.AuthUserResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserInfo user_info = 3;
 * @return {?proto.ImgPro.UserInfo}
 */
proto.ImgPro.AuthUserResponse.prototype.getUserInfo = function() {
  return /** @type{?proto.ImgPro.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.ImgPro.UserInfo, 3));
};


/** @param {?proto.ImgPro.UserInfo|undefined} value */
proto.ImgPro.AuthUserResponse.prototype.setUserInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.ImgPro.AuthUserResponse.prototype.clearUserInfo = function() {
  this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ImgPro.AuthUserResponse.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string user_token = 4;
 * @return {string}
 */
proto.ImgPro.AuthUserResponse.prototype.getUserToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.ImgPro.AuthUserResponse.prototype.setUserToken = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 user_expires_in = 5;
 * @return {number}
 */
proto.ImgPro.AuthUserResponse.prototype.getUserExpiresIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.ImgPro.AuthUserResponse.prototype.setUserExpiresIn = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.ModifyUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.ModifyUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.ModifyUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ModifyUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userInfo: (f = msg.getUserInfo()) && proto.ImgPro.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.ModifyUserRequest}
 */
proto.ImgPro.ModifyUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.ModifyUserRequest;
  return proto.ImgPro.ModifyUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.ModifyUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.ModifyUserRequest}
 */
proto.ImgPro.ModifyUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ImgPro.UserInfo;
      reader.readMessage(value,proto.ImgPro.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.ModifyUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.ModifyUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.ModifyUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ModifyUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ImgPro.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserInfo user_info = 1;
 * @return {?proto.ImgPro.UserInfo}
 */
proto.ImgPro.ModifyUserRequest.prototype.getUserInfo = function() {
  return /** @type{?proto.ImgPro.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.ImgPro.UserInfo, 1));
};


/** @param {?proto.ImgPro.UserInfo|undefined} value */
proto.ImgPro.ModifyUserRequest.prototype.setUserInfo = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.ImgPro.ModifyUserRequest.prototype.clearUserInfo = function() {
  this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ImgPro.ModifyUserRequest.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.ModifyUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.ModifyUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.ModifyUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ModifyUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userInfo: (f = msg.getUserInfo()) && proto.ImgPro.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.ModifyUserResponse}
 */
proto.ImgPro.ModifyUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.ModifyUserResponse;
  return proto.ImgPro.ModifyUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.ModifyUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.ModifyUserResponse}
 */
proto.ImgPro.ModifyUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.AuthErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.ImgPro.UserInfo;
      reader.readMessage(value,proto.ImgPro.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.ModifyUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.ModifyUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.ModifyUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ModifyUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ImgPro.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthErrorCode error_code = 1;
 * @return {!proto.ImgPro.AuthErrorCode}
 */
proto.ImgPro.ModifyUserResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.ImgPro.AuthErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.AuthErrorCode} value */
proto.ImgPro.ModifyUserResponse.prototype.setErrorCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ImgPro.ModifyUserResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.ModifyUserResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserInfo user_info = 3;
 * @return {?proto.ImgPro.UserInfo}
 */
proto.ImgPro.ModifyUserResponse.prototype.getUserInfo = function() {
  return /** @type{?proto.ImgPro.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.ImgPro.UserInfo, 3));
};


/** @param {?proto.ImgPro.UserInfo|undefined} value */
proto.ImgPro.ModifyUserResponse.prototype.setUserInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.ImgPro.ModifyUserResponse.prototype.clearUserInfo = function() {
  this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ImgPro.ModifyUserResponse.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.ChangeUserPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.ChangeUserPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.ChangeUserPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ChangeUserPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldPassword: jspb.Message.getFieldWithDefault(msg, 3, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.ChangeUserPasswordRequest}
 */
proto.ImgPro.ChangeUserPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.ChangeUserPasswordRequest;
  return proto.ImgPro.ChangeUserPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.ChangeUserPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.ChangeUserPasswordRequest}
 */
proto.ImgPro.ChangeUserPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPassword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.ChangeUserPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.ChangeUserPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.ChangeUserPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ChangeUserPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string old_password = 3;
 * @return {string}
 */
proto.ImgPro.ChangeUserPasswordRequest.prototype.getOldPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.ImgPro.ChangeUserPasswordRequest.prototype.setOldPassword = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string new_password = 4;
 * @return {string}
 */
proto.ImgPro.ChangeUserPasswordRequest.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.ImgPro.ChangeUserPasswordRequest.prototype.setNewPassword = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.ChangeUserPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.ChangeUserPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.ChangeUserPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ChangeUserPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.ChangeUserPasswordResponse}
 */
proto.ImgPro.ChangeUserPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.ChangeUserPasswordResponse;
  return proto.ImgPro.ChangeUserPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.ChangeUserPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.ChangeUserPasswordResponse}
 */
proto.ImgPro.ChangeUserPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.AuthErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.ChangeUserPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.ChangeUserPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.ChangeUserPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ChangeUserPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AuthErrorCode error_code = 1;
 * @return {!proto.ImgPro.AuthErrorCode}
 */
proto.ImgPro.ChangeUserPasswordResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.ImgPro.AuthErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.AuthErrorCode} value */
proto.ImgPro.ChangeUserPasswordResponse.prototype.setErrorCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ImgPro.ChangeUserPasswordResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.ChangeUserPasswordResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.UserSubscribeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.UserSubscribeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.UserSubscribeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.UserSubscribeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.UserSubscribeRequest}
 */
proto.ImgPro.UserSubscribeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.UserSubscribeRequest;
  return proto.ImgPro.UserSubscribeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.UserSubscribeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.UserSubscribeRequest}
 */
proto.ImgPro.UserSubscribeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {!proto.ImgPro.SubscribeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.UserSubscribeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.UserSubscribeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.UserSubscribeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.UserSubscribeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional SubscribeType type = 3;
 * @return {!proto.ImgPro.SubscribeType}
 */
proto.ImgPro.UserSubscribeRequest.prototype.getType = function() {
  return /** @type {!proto.ImgPro.SubscribeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.ImgPro.SubscribeType} value */
proto.ImgPro.UserSubscribeRequest.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.UserSubscribeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.UserSubscribeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.UserSubscribeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.UserSubscribeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userInfo: (f = msg.getUserInfo()) && proto.ImgPro.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.UserSubscribeResponse}
 */
proto.ImgPro.UserSubscribeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.UserSubscribeResponse;
  return proto.ImgPro.UserSubscribeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.UserSubscribeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.UserSubscribeResponse}
 */
proto.ImgPro.UserSubscribeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.AuthErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.ImgPro.UserInfo;
      reader.readMessage(value,proto.ImgPro.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.UserSubscribeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.UserSubscribeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.UserSubscribeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.UserSubscribeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ImgPro.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthErrorCode error_code = 1;
 * @return {!proto.ImgPro.AuthErrorCode}
 */
proto.ImgPro.UserSubscribeResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.ImgPro.AuthErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.AuthErrorCode} value */
proto.ImgPro.UserSubscribeResponse.prototype.setErrorCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ImgPro.UserSubscribeResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.UserSubscribeResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserInfo user_info = 3;
 * @return {?proto.ImgPro.UserInfo}
 */
proto.ImgPro.UserSubscribeResponse.prototype.getUserInfo = function() {
  return /** @type{?proto.ImgPro.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.ImgPro.UserInfo, 3));
};


/** @param {?proto.ImgPro.UserInfo|undefined} value */
proto.ImgPro.UserSubscribeResponse.prototype.setUserInfo = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.ImgPro.UserSubscribeResponse.prototype.clearUserInfo = function() {
  this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ImgPro.UserSubscribeResponse.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.ExitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.ExitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.ExitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ExitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.ExitRequest}
 */
proto.ImgPro.ExitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.ExitRequest;
  return proto.ImgPro.ExitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.ExitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.ExitRequest}
 */
proto.ImgPro.ExitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.ExitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.ExitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.ExitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ExitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.ExitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.ExitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.ExitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ExitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.ExitResponse}
 */
proto.ImgPro.ExitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.ExitResponse;
  return proto.ImgPro.ExitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.ExitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.ExitResponse}
 */
proto.ImgPro.ExitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.AuthErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.ExitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.ExitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.ExitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ExitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AuthErrorCode error_code = 1;
 * @return {!proto.ImgPro.AuthErrorCode}
 */
proto.ImgPro.ExitResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.ImgPro.AuthErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.AuthErrorCode} value */
proto.ImgPro.ExitResponse.prototype.setErrorCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ImgPro.ExitResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.ExitResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.ImgPro.AuthErrorCode = {
  AUTH_SUCCEED: 0,
  AUTH_INVALID_INPUT: 1,
  AUTH_FAILED: 2,
  AUTH_PASSWORD_WEAK: 3,
  AUTH_TOKEN_PARSER_FAILED: 4,
  AUTH_WRONG_OLD_PASSWORD: 5,
  AUTH_USERNAME_USED: 6,
  AUTH_EMAIL_USED: 7,
  AUTH_USERNAME_OR_PASSWORD_WRONG: 8,
  AUTH_USER_DISABLED: 9,
  AUTH_INVALID_USER: 10,
  AUTH_USAGE_UP: 11,
  AUTH_OTHERS: 12
};

/**
 * @enum {number}
 */
proto.ImgPro.AuthType = {
  AUTH_USER_PASSWORD: 0,
  AUTH_WECHAT: 1,
  AUTH_QQ: 2,
  AUTH_FACEBOOK: 3,
  AUTH_GOOGLE: 4
};

/**
 * @enum {number}
 */
proto.ImgPro.UserType = {
  NORMAL: 0,
  PREMIUM: 1
};

/**
 * @enum {number}
 */
proto.ImgPro.BooleanType = {
  ENABLE: 0,
  DISABLE: 1
};

/**
 * @enum {number}
 */
proto.ImgPro.VerifyType = {
  VERIFY_EMAIL: 0,
  VERIFY_WECHAT: 1,
  VERIFY_QQ: 2,
  VERIFY_FACEBOOK: 3,
  VERIFY_GOOGLE: 4
};

/**
 * @enum {number}
 */
proto.ImgPro.SubscribeType = {
  MONTHLY: 0,
  YEARLY: 1
};

goog.object.extend(exports, proto.ImgPro);

export interface RemainingUsageAmount {
    Compress: number;
    Convert: number;
    Resize: number;
    Colourize: number;
    Enhance: number;
    Enlarge: number;
    Animate: number;
    Remove: number;
    Restore: number;
};

const BASIC_USAGE_LIMITS: RemainingUsageAmount = {
    Compress: 9999,
    Convert: 9999,
    Resize: 9999,
    Colourize: 5,
    Enhance: 10,
    Enlarge: 10,
    Animate: 0,
    Remove: 0,
    Restore: 10,
};

const FREE_TRIAL_USAGE_LIMITS: RemainingUsageAmount = {
    Compress: 9999,
    Convert: 9999,
    Resize: 9999,
    Colourize: 20,
    Enhance: 50,
    Enlarge: 50,
    Animate: 20,
    Remove: 20,
    Restore: 50,
};

const USER_TYPE = {
    INVALID_USER: 0,// invalid user
    UNREGISTERED_USER: 1,// not registered user
    UNVERIFIED_USER: 2, // registered but not verified user
    FREE_TRIAL_USER: 3, // registered free trial user
    REGISTERED_USER: 4, // registered and not free trial user
    PREMIUM_USER: 5,// registered premium user
};

const ACTION_TYPE = {
    ACTION_TYPE_COMPRESSION: 0,
    ACTION_TYPE_CONVERT: 1,
    ACTION_TYPE_RESIZE: 2,
    ACTION_TYPE_ANIMATE: 3,
    ACTION_TYPE_COLOURIZE: 4,
    ACTION_TYPE_ENHANCE_DEFINITION: 5,
    ACTION_TYPE_ENLARGE_SIZE: 6,
    ACTION_TYPE_REMOVE_BACKGROUND: 7,
    ACTION_TYPE_RESTORE_STRETCH: 8,
};

const LIMITATION_USE_UP = "Limitation is used up";

const SUGGEST_TYPE = {
    SUGGEST_TYPE_PREMIUM_FIRST_LOGIN_TO_TRIAL: 0,
    SUGGEST_TYPE_USEUP_FIRST_LOGIN_TO_TRIAL: 1,
    SUGGEST_TYPE_PREMIUM_NEED_VERIFY_EMAIL: 2,
    SUGGEST_TYPE_USEUP_NEED_VERIFY_EMAIL: 3,
    SUGGEST_TYPE_PREMIUM_NEED_SUBSCRIBE: 4,
    SUGGEST_TYPE_USEUP_NEED_SUBSCRIBE: 5,
    SUGGEST_TYPE_TRIAL_USEUP_NEED_SUBSCRIBE: 6,
    SUGGEST_TYPE_NEED_RESIGN: 7,
};

export {
    BASIC_USAGE_LIMITS,
    FREE_TRIAL_USAGE_LIMITS,
    USER_TYPE,
    ACTION_TYPE,
    LIMITATION_USE_UP,
    SUGGEST_TYPE,
};
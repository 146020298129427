import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import { Button, ConfigProvider, notification } from 'antd';
import i18nSrv from './services/i18nSrv';
import Intl_en from './locales/en';
import Intl_zh from './locales/zh';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/zh';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/zh';

import Home from './pages/home/home';
import Compress from './pages/tools/compress/compress';
import EnlargeSize from './pages/tools/enlarge-size/enlarge-size';
import Convert from './pages/tools/covert/convert';
import Resize from './pages/tools/resize/resize';
import RestoreStretching from './pages/tools/restore-stretching/restore-stretching';
import Animate from './pages/tools/animate/animate';
import Colourize from './pages/tools/colourize/colourize';
import EnhanceDefinition from './pages/tools/enhance-definition/enhance-definition';
import RemoveBackground from './pages/tools/remove-background/remove-background';
import UserInfo from './pages/user/user-info/user-info';
import UserPremium from './pages/user/user-premium/user-premium';
import TOU from './pages/tou/tou';
import Privacy from './pages/privacy/privacy';
import NotFound from './pages/not-found/not-found';

import usermanager from './services/usermanager';
import userInfoSrv from './services/userInfoSrv';

interface AppState {
  ant_locale: any;
  intl_locale: string;
  intl_messages: any;
}

export default class App extends React.Component<{}, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ant_locale: enUS,
      intl_locale: "en",
      intl_messages: Intl_en,
    };

    i18nSrv.Init(this.refreshLocale.bind(this));
  }

  componentDidMount() {
    this.refreshLocale();
    usermanager.AuthClient();
    userInfoSrv.SaveUserFingerPrint();
  }

  render() {
    return (
      <ConfigProvider locale={this.state.ant_locale}>
        <IntlProvider messages={this.state.intl_messages} locale={this.state.intl_locale}>
          <Router>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/compress">
                <Compress />
              </Route>
              <Route exact path="/enlarge-size">
                <EnlargeSize />
              </Route>
              <Route exact path="/convert">
                <Convert />
              </Route>
              <Route exact path="/resize">
                <Resize />
              </Route>
              <Route exact path="/restore-stretching">
                <RestoreStretching />
              </Route>
              <Route exact path="/animate">
                <Animate />
              </Route>
              <Route exact path="/colourize">
                <Colourize />
              </Route>
              <Route exact path="/enhance-definition">
                <EnhanceDefinition />
              </Route>
              <Route exact path="/remove-background">
                <RemoveBackground />
              </Route>
              <Route exact path="/user">
                <UserInfo />
              </Route>
              <Route exact path="/user/premium">
                <UserPremium />
              </Route>
              <Route exact path="/tou">
                <TOU />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route path="/*">
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </IntlProvider>
      </ConfigProvider>
    );
  }

  refreshLocale() {
    let locale: string = i18nSrv.GetLocale();
    if (locale === "zh") {
      this.setState({
        ant_locale: zhCN,
        intl_locale: "zh",
        intl_messages: Intl_zh,
      });
      return;
    } else if (locale === "en") {
      this.setState({
        ant_locale: enUS,
        intl_locale: "en",
        intl_messages: Intl_en,
      });
      return;
    }

    this.setState({
      ant_locale: enUS,
      intl_locale: "en",
      intl_messages: Intl_en,
    });

    if (this.getBrowserLocal() === "zh") {
      this.openLanguageChangeDialog();
    }
  };

  openLanguageChangeDialog() {
    const key = `open${Date.now()}`;
    notification.info({
      key,
      message: '更换语言为简体中文？',
      description: <>
        <Button style={{ margin: '5px 10px' }} onClick={
          () => {
            i18nSrv.ChangeLocale("en");
            notification.close(key);
          }
        }>否</Button>
        <Button type="primary" style={{ margin: '5px 10px' }} onClick={
          () => {
            i18nSrv.ChangeLocale("zh");
            notification.close(key);
          }
        }>是</Button>
      </>,
      placement: 'bottomLeft',
      duration: 0,
      onClose: () => {
        i18nSrv.ChangeLocale("en");
        notification.close(key);
      },
    });
  }

  getBrowserLocal() {
    let browser: any = navigator;
    let lang: string = browser.appName === "Netscape" ? browser.language : browser.userLanguage;

    lang = lang.substr(0, 2).toLowerCase();
    return lang === "en" ? "en" : "zh";
  }
}

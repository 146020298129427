// package: ImgPro
// file: authmanager.proto

var authmanager_pb = require("./authmanager_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AuthManager = (function () {
  function AuthManager() {}
  AuthManager.serviceName = "ImgPro.AuthManager";
  return AuthManager;
}());

AuthManager.AuthClient = {
  methodName: "AuthClient",
  service: AuthManager,
  requestStream: false,
  responseStream: false,
  requestType: authmanager_pb.AuthClientRequest,
  responseType: authmanager_pb.AuthClientResponse
};

AuthManager.RegisterUser = {
  methodName: "RegisterUser",
  service: AuthManager,
  requestStream: false,
  responseStream: false,
  requestType: authmanager_pb.RegisterUserRequest,
  responseType: authmanager_pb.RegisterUserResponse
};

AuthManager.AuthUser = {
  methodName: "AuthUser",
  service: AuthManager,
  requestStream: false,
  responseStream: false,
  requestType: authmanager_pb.AuthUserRequest,
  responseType: authmanager_pb.AuthUserResponse
};

AuthManager.ModifyUser = {
  methodName: "ModifyUser",
  service: AuthManager,
  requestStream: false,
  responseStream: false,
  requestType: authmanager_pb.ModifyUserRequest,
  responseType: authmanager_pb.ModifyUserResponse
};

AuthManager.ChangeUserPassword = {
  methodName: "ChangeUserPassword",
  service: AuthManager,
  requestStream: false,
  responseStream: false,
  requestType: authmanager_pb.ChangeUserPasswordRequest,
  responseType: authmanager_pb.ChangeUserPasswordResponse
};

AuthManager.UserSubscribe = {
  methodName: "UserSubscribe",
  service: AuthManager,
  requestStream: false,
  responseStream: false,
  requestType: authmanager_pb.UserSubscribeRequest,
  responseType: authmanager_pb.UserSubscribeResponse
};

AuthManager.ExitUser = {
  methodName: "ExitUser",
  service: AuthManager,
  requestStream: false,
  responseStream: false,
  requestType: authmanager_pb.ExitRequest,
  responseType: authmanager_pb.ExitResponse
};

exports.AuthManager = AuthManager;

function AuthManagerClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AuthManagerClient.prototype.authClient = function authClient(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthManager.AuthClient, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthManagerClient.prototype.registerUser = function registerUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthManager.RegisterUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthManagerClient.prototype.authUser = function authUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthManager.AuthUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthManagerClient.prototype.modifyUser = function modifyUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthManager.ModifyUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthManagerClient.prototype.changeUserPassword = function changeUserPassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthManager.ChangeUserPassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthManagerClient.prototype.userSubscribe = function userSubscribe(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthManager.UserSubscribe, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AuthManagerClient.prototype.exitUser = function exitUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AuthManager.ExitUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.AuthManagerClient = AuthManagerClient;


import React from 'react';
import './home.css';
import CommonHeader from '../../components/header/header';
import ToolList from '../../components/toolList/toolList';
import { BackTop, Layout } from 'antd';
import { FormattedMessage } from 'react-intl';
import InstructionList from '../../components/introductionList/introductionList';
import { SketchOutlined } from '@ant-design/icons';

const { Footer } = Layout;

export default class Home extends React.Component {
    render() {
        return (
            <div className="home">
                <BackTop />
                <CommonHeader></CommonHeader>
                <div className="home-tool-area">
                    <div className="home-tool-motto"><FormattedMessage id="motto" /></div>
                    <div className="home-tool-list">
                        <ToolList></ToolList>
                    </div>
                </div>
                <div className="home-site-introduction">
                    <InstructionList></InstructionList>
                </div>
                <div className="home-site-subscription">
                    <div className="subscription-title"><FormattedMessage id="subscription-title" /></div>
                    <div className="subscription-description"><FormattedMessage id="subscription-description" /></div>
                    <a href="/user/premium" className="subscription-btn"><SketchOutlined style={{ paddingTop: '2px' }}/>&nbsp;<FormattedMessage id="subscription" /></a>
                </div>
                <Footer style={{ textAlign: 'center' }}>ImgPro ©2021~2026 Created by BarretX</Footer>
            </div>
        );
    }
}
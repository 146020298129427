import {
    AuthClientRequest,
    AuthClientResponse,
    RegisterUserRequest,
    RegisterUserResponse,
    AuthUserRequest,
    AuthUserResponse,
    ModifyUserRequest,
    ModifyUserResponse,
    UserSubscribeRequest,
    UserSubscribeResponse,
    ExitRequest,
    ExitResponse,
} from '../grpc/authmanager_pb';
import {
    AuthManagerClient
} from '../grpc/authmanager_pb_service';
import { UserManagerIntercepteInvoke } from "./interceptor";


//const host = "http://192.168.31.134:19999";
const host = "http://" + window.location.hostname +":"+ window.location.port;
class UserManagerSrv {
    client: AuthManagerClient = new AuthManagerClient(host);

    AuthClient(request: AuthClientRequest): Promise<AuthClientResponse> {
        return UserManagerIntercepteInvoke(this.client.authClient.bind(this.client), request);
    }

    RegisterUser(request: RegisterUserRequest): Promise<RegisterUserResponse> {
        return UserManagerIntercepteInvoke(this.client.registerUser.bind(this.client), request);
    }

    AuthUser(request: AuthUserRequest): Promise<AuthUserResponse> {
        return UserManagerIntercepteInvoke(this.client.authUser.bind(this.client), request);
    }

    ModifyUser(request: ModifyUserRequest): Promise<ModifyUserResponse> {
        return UserManagerIntercepteInvoke(this.client.modifyUser.bind(this.client), request);
    }

    ChangeUserPassword(request: AuthUserRequest): Promise<AuthUserResponse> {
        return UserManagerIntercepteInvoke(this.client.changeUserPassword.bind(this.client), request);
    }

    UserSubscribe(request: UserSubscribeRequest): Promise<UserSubscribeResponse> {
        return UserManagerIntercepteInvoke(this.client.userSubscribe.bind(this.client), request);
    }

    ExitUser(request: ExitRequest): Promise<ExitResponse> {
        return UserManagerIntercepteInvoke(this.client.exitUser.bind(this.client), request);
    }
}

let usermanagerSvc = new UserManagerSrv();
export default usermanagerSvc;
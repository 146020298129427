import CryptoJS from 'crypto-js';

// reference https://mojotv.cn/go/crypto-js-with-golang
class EncryptService {
    AESEncrypt(message: string, key: string, length: number) {
        key = this.paddingLeft(key, length);
        let ivkey = CryptoJS.enc.Utf8.parse(key);
        var encrypted = CryptoJS.AES.encrypt(message, ivkey, {
            iv: ivkey,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    }

    paddingLeft(key: string, length: number) {
        let pkey = key.toString();
        let l = pkey.length;
        if (l < length) {
            pkey = new Array(length - l + 1).join('0') + pkey;
        } else if (l > length) {
            pkey = pkey.slice(length);
        }
        return pkey;
    }
}

let encryptSrv = new EncryptService();
export default encryptSrv;
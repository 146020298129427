import { EyeInvisibleOutlined, EyeTwoTone, FacebookFilled, GoogleCircleFilled, QqCircleFilled } from '@ant-design/icons';
import { Button, Divider, Input, message, Modal } from 'antd';
import React from 'react';
import { FaLock, FaUserCircle } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import { FormattedMessage, injectIntl } from 'react-intl';
import { PasswordSignInInfo, RegisterInfo } from '../../models/authModel';
import './signIn.css';
const Login_Mode = {
    PASSWORD_SIGNIN: 0,
    QQ_SIGNIN: 1,
    WECHAT_SIGNIN: 2,
    FACEBOOK_SIGNIN: 3,
    GOOGLE_SIGNIN: 4,
    REGISTER: 5,
};


class SignIn extends React.Component<any, any> {
    state: any = {
        loginMode: Login_Mode.PASSWORD_SIGNIN,
    };

    onSignIn = () => {
        if (this.validateSignInInfo()) {
            this.props.signInWithPassword(this.passwordSignInInfo);
        }
    };
    passwordSignInInfo: PasswordSignInInfo = {
        username_or_email: "",
        password: "",
    };
    renderPasswordSignIn() {
        let intl: any = this.props.intl;
        return (
            <div className="signin-current-login" onKeyPress={e => {
                let keyCode = e.keyCode || e.which || e.charCode;
                if (keyCode === 13) {
                    this.onSignIn();
                }
            }}>
                <div>
                    <FormattedMessage id="new-user" />&nbsp;
                    <Button type="link" className="signin-item-inline-link"
                        onClick={() => { this.setState({ loginMode: Login_Mode.REGISTER }) }}
                    >
                        <FormattedMessage id="to-sign-up" />
                    </Button>
                </div>
                <Input className="signin-item" size="large" allowClear
                    placeholder={intl.formatMessage({ id: 'input-name-or-email' })}
                    prefix={<FaUserCircle />}
                    onChange={e => { this.passwordSignInInfo.username_or_email = e.target.value }}
                />
                <Input.Password className="signin-item" size="large" allowClear
                    placeholder={intl.formatMessage({ id: 'input-password' })}
                    prefix={<FaLock />}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={e => { this.passwordSignInInfo.password = e.target.value }}
                />
                <Button className="signin-item" type="primary" size='large' block
                    onClick={this.onSignIn}
                >
                    <FormattedMessage id="sign-in" />
                </Button>
                <Button type="link" block>
                    <FormattedMessage id="forgot-password" />
                </Button>
            </div>
        );
    }

    onSignUp = () => {
        if (this.validateRegisterInfo()) {
            this.props.register(this.registerInfo);
        }
    };
    registerInfo: RegisterInfo = {
        username: "",
        email: "",
        password: "",
    };
    renderRegister() {
        let intl: any = this.props.intl;
        return (
            <div className="signin-current-login" onKeyPress={e => {
                let keyCode = e.keyCode || e.which || e.charCode;
                if (keyCode === 13) {
                    this.onSignUp();
                }
            }}>
                <div>
                    <FormattedMessage id="already-have-account" />&nbsp;
                    <Button type="link" className="signin-item-inline-link"
                        onClick={() => { this.setState({ loginMode: Login_Mode.PASSWORD_SIGNIN }) }}
                    >
                        <FormattedMessage id="to-sign-in" />
                    </Button>
                </div>
                <Input className="signin-item" size="large" allowClear
                    placeholder={intl.formatMessage({ id: 'input-name' })}
                    prefix={<FaUserCircle />}
                    onChange={e => { this.registerInfo.username = e.target.value }}
                />
                <Input className="signin-item" size="large" allowClear
                    placeholder={intl.formatMessage({ id: 'input-email' })}
                    prefix={<MdEmail />}
                    onChange={e => { this.registerInfo.email = e.target.value }}
                />
                <Input.Password className="signin-item" size="large" allowClear
                    placeholder={intl.formatMessage({ id: 'input-password' })}
                    prefix={<FaLock />}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={e => { this.registerInfo.password = e.target.value }}
                />
                <Button className="signin-item" type="primary" size='large' block
                    onClick={this.onSignUp}
                >
                    <FormattedMessage id="sign-up" />
                </Button>
            </div>
        );
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                maskClosable={false}
                width={380}
                title={<FormattedMessage id="SignIn" />}
                onCancel={this.props.close}
                destroyOnClose={true}
                footer={[
                    <div className="signin-modal-footer" key="signin-modal-footer">
                        <FormattedMessage id="login-to-agree" />&nbsp;
                        <a target="_blank" href="/tou"><FormattedMessage id="tou" /></a>
                        &nbsp;<FormattedMessage id="and" />&nbsp;
                        <a target="_blank" href="/privacy"><FormattedMessage id="privacy" /></a>
                    </div>
                ]}
            >
                {this.state.loginMode === Login_Mode.PASSWORD_SIGNIN &&
                    this.renderPasswordSignIn()
                }

                {this.state.loginMode === Login_Mode.REGISTER &&
                    this.renderRegister()
                }

                <Divider plain><FormattedMessage id="other-logins" /></Divider>
                <div className="signin-others">
                    <FaUserCircle className="signin-other-btn" />
                    <QqCircleFilled className="signin-other-btn" />
                    <GoogleCircleFilled className="signin-other-btn" />
                    <FacebookFilled className="signin-other-btn" />
                </div>
            </Modal>
        );
    }

    validateSignInInfo = () => {
        let intl: any = this.props.intl;
        if (!this.passwordSignInInfo.username_or_email ||
            !this.passwordSignInInfo.password) {
            message.error(intl.formatMessage({ id: 'not-complete-input' }));
            return false;
        }

        if (!this.isEmailValid(this.passwordSignInInfo.username_or_email) &&
            !this.isUserNameValid(this.passwordSignInInfo.username_or_email)) {
            message.error(intl.formatMessage({ id: 'username-email-format-wrong' }));
            return false;
        }

        return true;
    }

    validateRegisterInfo = () => {
        let intl: any = this.props.intl;
        if (!this.registerInfo.username ||
            !this.registerInfo.email ||
            !this.registerInfo.password) {
            message.error(intl.formatMessage({ id: 'not-complete-input' }));
            return false;
        }

        if (!this.isUserNameValid(this.registerInfo.username)) {
            message.error(intl.formatMessage({ id: 'username-format-wrong' }));
            return false;
        }

        if (!this.isEmailValid(this.registerInfo.email)) {
            message.error(intl.formatMessage({ id: 'email-format-wrong' }));
            return false;
        }

        if (!this.isPasswordValid(this.registerInfo.password)) {
            message.error(intl.formatMessage({ id: 'password-format-wrong' }));
            return false;
        }

        return true;
    }

    isUserNameValid = (username: string) => {
        //5-20个以字母开头、可带数字、“_”、“.”的字串
        // eslint-disable-next-line
        let regex = /^[a-zA-Z]{1}([a-zA-Z0-9]|[._]){4,19}$/;
        return regex.test(username);
    }

    isEmailValid = (email: string) => {
        // eslint-disable-next-line
        let regex = /^([a-zA-Z]|[0-9])(\w|\-|[._])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        return regex.test(email);
    }

    isPasswordValid = (password: string) => {
        //包括字母，数字，不许有空格，8位以上
        // eslint-disable-next-line
        let regex = /^(?=\S*[a-zA-Z])(?=\S*\d)\S{8,}$/;
        return regex.test(password);
    }
}

export default injectIntl(SignIn);
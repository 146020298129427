import { MailOutlined } from '@ant-design/icons';
import React from 'react';
import './fqa.css';
import { FormattedMessage } from 'react-intl';
import FQAItem from './fqaItem/fqaItem';
import { FQAItemData } from '../../models/fqaModel';

interface FQAData{
    title: string;
    listData: Array<FQAItemData>;
}

interface FQAProps {
    fqaData: FQAData;
}

export default class FQA extends React.Component<FQAProps, {}> {
    render() {
        return (
            <div className="fqa">
                <div className="fqa-list">
                    {!!this.props.fqaData &&
                        <div className="fqa-list-title">{this.props.fqaData.title}</div>
                    }
                    {!!this.props.fqaData &&
                        this.props.fqaData.listData.map((data: FQAItemData, index: number) => {
                            return <FQAItem data={data} key={index}></FQAItem>;
                        })
                    }
                    <a className="fqa-email" href="mailto:imgpro.top@gmail.com"><FormattedMessage id="still-have-question" />&nbsp;&nbsp;<MailOutlined /></a>
                </div>
            </div>
        );
    }
}
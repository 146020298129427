import React from 'react';
import CommonHeader from '../../components/header/header';
import './not-found.css';
import { Result, Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';


class NotFound extends React.Component<any, {}> {
    render() {
        const intl = this.props.intl;
        return (
            <div>
                <CommonHeader></CommonHeader>
                <Result
                    status="404"
                    title="404"
                    subTitle={intl.formatMessage({ id: 'msg_404' })}
                    extra={
                        <Button type="primary" key="not-found" icon={<HomeOutlined />} onClick={() => {
                            window.location.href = "/";
                        }}>
                            <FormattedMessage id="backToHome" />
                        </Button>
                    }
                />
            </div>
        );
    }
}

export default injectIntl(NotFound);
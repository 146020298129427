import cookieSrv from "./cookieSrv";

interface HandleFunc {
    (): void;
}

class I18nService {
    _refreshHanlder!: HandleFunc;

    Init(refreshHanlder: HandleFunc) {
        this._refreshHanlder = refreshHanlder;
    }

    ChangeLocale(locale: string) {
        cookieSrv.setCookie("lang", locale, 60 * 60 * 24);
        this._refreshHanlder();
    }

    GetLocale(): string {
        return cookieSrv.getCookie("lang");
    }
}

let i18nSrv = new I18nService();
export default i18nSrv;
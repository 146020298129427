import React from 'react';
import './introductionList.css';
import { FormattedMessage } from 'react-intl';
import qualityLogo from '../../assets/quality.svg';
import fastLogo from '../../assets/fast.svg';
import easyToUseLogo from '../../assets/easyToUse.svg';
import anywhereLogo from '../../assets/anywhere.svg';
import safetyLogo from '../../assets/safety.svg';
import freeLogo from '../../assets/free.svg';
import IntroductionItem from './introductionItem/introductionItem';
import { Introduction } from '../../models/instruction';

export default class InstructionList extends React.Component {
    instructionListData: Array<Introduction> = [
        {
            imgSrc: qualityLogo,
            title: <FormattedMessage id="instroduction-quality-title" />,
            describe: <FormattedMessage id="instroduction-quality-description" />,
        },
        {
            imgSrc: fastLogo,
            title: <FormattedMessage id="instroduction-fast-title" />,
            describe: <FormattedMessage id="instroduction-fast-description" />,
        },
        {
            imgSrc: easyToUseLogo,
            title: <FormattedMessage id="instroduction-easytouse-title" />,
            describe: <FormattedMessage id="instroduction-easytouse-description" />,
        },
        {
            imgSrc: anywhereLogo,
            title: <FormattedMessage id="instroduction-anywhere-title" />,
            describe: <FormattedMessage id="instroduction-anywhere-description" />,
        },
        {
            imgSrc: safetyLogo,
            title: <FormattedMessage id="instroduction-safety-title" />,
            describe: <FormattedMessage id="instroduction-safety-description" />,
        },
        {
            imgSrc: freeLogo,
            title: <FormattedMessage id="instroduction-free-title" />,
            describe: <FormattedMessage id="instroduction-free-description" />,
        },
    ];
    render() {
        return (
            <div className="introductionList-list">
                {
                    this.instructionListData.map((data, index) => {
                        return <IntroductionItem data={data} key={index}></IntroductionItem>;
                    })
                }
            </div>
        );
    }
}
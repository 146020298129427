import React from 'react';
import CommonHeader from '../../../components/header/header';
import './remove-background.css';
import { Upload, message, Layout, Modal, Button, Result, Spin } from 'antd';
import { DownloadOutlined, InboxOutlined, LoadingOutlined, PlusOutlined, SafetyOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import fileSrv from '../../../services/fileSrv';
import {
    CommonReply,
    CommonRequest,
    Image,
} from '../../../grpc/imgpro_pb';
import imgprocessorSvc from '../../../grpcSrv/imgprocessorSrv';
import JSZip from 'jszip';
import saveAs from '../../../services/fileSaver';
import ShareDonate from '../../../components/shareDonate/shareDonate';
import FQA from '../../../components/fqa/fqa';
import WorkMode from '../../../models/workMode';
import { FQAData } from '../../../models/fqaModel';
import remove_before from '../../../assets/remove_before.jpg';
import remove_after from '../../../assets/remove_after.jpg';
import { FunctionDemoData } from '../../../models/functionDemoModel';
import FunctionDemo from '../../../components/functionDemo/functionDemo';
import { LIMITATION_USE_UP } from '../../../models/usageModel';

const { Footer } = Layout;
const { Dragger } = Upload;

class RemoveBackground extends React.Component<any, any> {
    results: Array<Image> = [];
    constructor(props: any) {
        super(props);

        this.state = {
            mode: WorkMode.INTRO_MODE,
            isUploading: false,
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: [],
        };
    }

    validateFile(file: File, toPrint: boolean): boolean {
        const intl = this.props.intl;
        const isJpgOrPngOrBmp = fileSrv.isJpgOrPngOrBmp(file);
        if (!isJpgOrPngOrBmp && toPrint) {
            message.error(intl.formatMessage({ id: 'jpg-or-png-or-bmp' }));
        }
        const isLt8M = fileSrv.isLessThan8M(file);
        if (!isLt8M && toPrint) {
            message.error(intl.formatMessage({ id: 'small-then-8m' }));
        }
        return isJpgOrPngOrBmp && isLt8M;
    }

    customRequest = (options: any) => {
        if (this.validateFile(options.file, false)) {
            options.onSuccess();
        } else {
            options.onError();
        }
    };

    beforeUpload = (file: File) => {
        this.setState({ isUploading: true });
        this.validateFile(file, true);

        return new Promise((reslove, reject) => {
            setTimeout(() => {
                reslove(true); // alway upload
            });
        });
    }

    onDraggerChange = (info: any) => {
        if (this.state.mode !== WorkMode.INTRO_MODE ||
            info.file.status === "uploading") {
            return;
        }

        let fileList: any = [];
        for (let index = 0; index < info.fileList.length; index++) {
            const file = info.fileList[index];
            if (file.status === "uploading") {
                return;
            }

            fileList.push(file);
        }

        this.setState({
            mode: WorkMode.USER_MODE,
            isUploading: false,
            fileList
        });
    };

    onUploadChange = (info: any) => {
        this.setState({ fileList: info.fileList });

        for (let index = 0; index < info.fileList.length; index++) {
            const file = info.fileList[index];
            if (file.status === "uploading") {
                return;
            }
        }
        this.setState({ isUploading: false });
    };

    getValidFileList(): any[] {
        let fileList = [];
        for (let index = 0; index < this.state.fileList.length; index++) {
            const file = this.state.fileList[index];
            if (file.status === "done") {
                fileList.push(file);
            }
        }
        return fileList;
    }

    onRemoveBackground = async () => {
        const intl = this.props.intl;
        let fileList = this.getValidFileList();
        if (fileList.length === 0) {
            message.error(intl.formatMessage({ id: 'need-upload-file' }));
            return;
        }

        this.setState({ mode: WorkMode.WORKING_MODE });
        let request: CommonRequest = new CommonRequest();
        for (let index = 0; index < fileList.length; index++) {
            const file = fileList[index];
            let img: Image = new Image();
            let imageBinary: ArrayBuffer = await fileSrv.getBinary(file.originFileObj);
            img.setData(new Uint8Array(imageBinary));
            let type = fileSrv.getImageType(file);
            img.setType(type as (0 | 1 | 2 | 3));
            request.addImages(img, index);
        }

        try {
            let reply: CommonReply = await imgprocessorSvc.RemoveBackground(request);
            this.results = reply.getImagesList();
            await this.onDownload();
            this.setState({ mode: WorkMode.DOWNLOAD_MODE });
        } catch (error) {
            if (error === LIMITATION_USE_UP) {
                this.setState({ mode: WorkMode.USER_MODE });
            } else {
                this.setState({ mode: WorkMode.FAILED_MODE });
            }
        }
    }

    onDownload = async () => {
        let zip = new JSZip();
        let oldFileList = this.getValidFileList();
        for (let index = 0; index < this.results.length; index++) {
            const file = oldFileList[index];
            const fileName = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
            const imageData = this.results[index].getData() as Uint8Array;
            if (this.results.length === 1) {
                saveAs(new Blob([imageData]), fileName);
                return;
            }

            zip.file(fileName, imageData);
        }

        const intl = this.props.intl;
        try {
            let content: Blob = await zip.generateAsync({ type: "blob" });
            let resultFileName: string = intl.formatMessage({ id: 'remove-background-result-file-name' });
            saveAs(content, resultFileName);
        } catch (error) {
            message.error(intl.formatMessage({ id: 'download-failed-msg' }));
        }
    }

    renderDragger() {
        let draggerProps: any = {
            name: 'file',
            multiple: true,
            accept: '.jpg, .jpeg, .png, .bmp',
            customRequest: this.customRequest,
            beforeUpload: this.beforeUpload,
            onChange: this.onDraggerChange,
        };
        const intl = this.props.intl;
        let fqa: FQAData = {
            title: intl.formatMessage({ id: 'fqa' }),
            listData: [
                {
                    question: intl.formatMessage({ id: 'fqa-1-question' }),
                    answer: intl.formatMessage({ id: 'fqa-1-anwser-5' }),
                },
                {
                    question: intl.formatMessage({ id: 'fqa-2-question' }),
                    answer: intl.formatMessage({ id: 'fqa-2-anwser' }),
                },
                {
                    question: intl.formatMessage({ id: 'fqa-3-question' }),
                    answer: intl.formatMessage({ id: 'fqa-3-anwser-1' }),
                },
                {
                    question: intl.formatMessage({ id: 'fqa-4-question' }),
                    answer: intl.formatMessage({ id: 'fqa-4-anwser' }),
                },
            ],
        };
        let functionDemoData: FunctionDemoData = {
            beforeImgSrc: remove_before,
            afterImgSrc: remove_after,
            beforeImgdesc: intl.formatMessage({ id: 'common-before' }),
            afterImgdesc: intl.formatMessage({ id: 'common-after' }),
        };
        return (
            <>
                <Spin tip={intl.formatMessage({ id: 'uploading' })} spinning={this.state.isUploading} size='large'>
                    <Dragger {...draggerProps} className="remove-background-img-dragger">
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined className="ant-upload-drag-icon-content" />
                        </p>
                        <p className="ant-upload-text"><FormattedMessage id="clickOrDragToRemoveBackground" /></p>
                        <p className="ant-upload-hint"><FormattedMessage id="remove-background-describe" /></p>
                    </Dragger>
                </Spin>
                <FunctionDemo data={functionDemoData}></FunctionDemo>
                <FQA fqaData={fqa}></FQA>
            </>
        );
    }

    renderRemoveBackground() {
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}><FormattedMessage id="add-images" /></div>
            </div>
        );
        let uploadProps: any = {
            listType: "picture-card",
            multiple: true,
            accept: '.jpg, .jpeg, .png, .bmp',
            customRequest: this.customRequest,
            beforeUpload: this.beforeUpload,
            onPreview: async (file: any) => {
                if (!file.url && !file.preview) {
                    file.preview = await fileSrv.getBase64(file.originFileObj);
                }

                this.setState({
                    previewImage: file.url || file.preview,
                    previewVisible: true,
                    previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
                });
            },
            onChange: this.onUploadChange,
        };
        const intl = this.props.intl;
        return (
            <div>
                <Spin tip={intl.formatMessage({ id: 'uploading' })} spinning={this.state.isUploading} size='large'>
                    <Upload fileList={this.state.fileList} {...uploadProps} className="remove-background-img-upload">
                        {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={() => this.setState({ previewVisible: false })}
                    >
                        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                    <div className="remove-background-img-ctl">
                        <div className="remove-background-img-title"><FormattedMessage id="remove-background" /></div>
                        <Button className="remove-background-img-btn" type="primary" size='large' onClick={this.onRemoveBackground}>
                            <FormattedMessage id="remove-background-images" />
                        </Button>
                    </div>
                </Spin>
            </div>
        );
    }

    renderWorking() {
        return (
            <div>
                <Result
                    icon={<LoadingOutlined />}
                    title={<FormattedMessage id="in-removing-background" />}
                    subTitle={<FormattedMessage id="in-removing-background-describe" />}
                />
                <ShareDonate />
            </div>
        );
    }

    renderDownload() {
        return (
            <div>
                <Result className="remove-background-img-download"
                    status="success"
                    title={<FormattedMessage id="remove-background-success-title" />}
                    subTitle={<><SafetyOutlined style={{ color: 'red' }} />&nbsp;<FormattedMessage id="delete-data" /></>}
                    extra={[
                        <Button className="remove-background-img-download-btn" key="download" type="primary" icon={<DownloadOutlined />} size='large' onClick={this.onDownload}>
                            &nbsp;<FormattedMessage id="download-images" />
                        </Button>,
                    ]}
                />
                <ShareDonate />
            </div>
        );
    }

    renderFailure() {
        return (
            <div>
                <Result
                    status="500"
                    title="500"
                    subTitle={<FormattedMessage id="server-error-msg" />}
                    extra={<Button type="primary" onClick={() => {
                        window.location.href = "/remove-background";
                    }}><FormattedMessage id="go-back" /></Button>}
                />
                <ShareDonate />
            </div>
        );
    }

    renderContent() {
        switch (this.state.mode) {
            case WorkMode.INTRO_MODE:
                return this.renderDragger();
            case WorkMode.USER_MODE:
                return this.renderRemoveBackground();
            case WorkMode.WORKING_MODE:
                return this.renderWorking();
            case WorkMode.DOWNLOAD_MODE:
                return this.renderDownload();
            case WorkMode.FAILED_MODE:
                return this.renderFailure();
        }
    }

    render() {
        return (
            <div className="remove-background-page">
                <CommonHeader></CommonHeader>
                {this.renderContent()}
                <Footer style={{ textAlign: 'center' }}>ImgPro ©2021~2026 Created by BarretX</Footer>
            </div>
        );
    }
}

export default injectIntl(RemoveBackground);
import { grpc } from "@improbable-eng/grpc-web";
import { USER_TOKEN_UPDATED } from "../models/authModel";
import { ACTION_TYPE, BASIC_USAGE_LIMITS, FREE_TRIAL_USAGE_LIMITS, LIMITATION_USE_UP, RemainingUsageAmount, USER_TYPE } from "../models/usageModel";
import cookieSrv from "../services/cookieSrv";
import subscribeSrv from "../services/subscribeSrv";
import userInfoSrv from "../services/userInfoSrv";

function getMetadata(): grpc.Metadata {
    return new grpc.Metadata({
        "client_auth": cookieSrv.getCookie("client_auth"),
        "user_auth": cookieSrv.getCookie("user_auth"),
        "user_fingerprint": cookieSrv.getCookie("user_fingerprint"),
    });
}

let ImgProcessorIntercepteInvoke = async (method: any, action_type: any, request: any) => {
    return new Promise<any>(async (reslove, reject) => {
        let user_type = userInfoSrv.GetUserType();
        switch (user_type) {
            case USER_TYPE.UNREGISTERED_USER:
            case USER_TYPE.UNVERIFIED_USER:
            case USER_TYPE.REGISTERED_USER:
                if (!checkUsageLimits(action_type, BASIC_USAGE_LIMITS)) {
                    console.error("Limition is used up");
                    subscribeSrv.SuggestSubscribe(isVipFunction(action_type));
                    reject(LIMITATION_USE_UP);
                    return;
                }
                break;
            case USER_TYPE.FREE_TRIAL_USER:
                if (!checkUsageLimits(action_type, FREE_TRIAL_USAGE_LIMITS)) {
                    console.error("Limition is used up");
                    subscribeSrv.SuggestSubscribe(isVipFunction(action_type));
                    reject(LIMITATION_USE_UP);
                    return;
                }
                break;
            case USER_TYPE.PREMIUM_USER:
                break;
            case USER_TYPE.INVALID_USER:
            default:
                console.error("Invalid user info");
                reject("Invalid user info");
                return;
        }

        method(request, getMetadata(), (err: any, response: any) => {
            if (!!err || !response) {
                console.error(err?.message);
                if (err?.message === LIMITATION_USE_UP) {
                    subscribeSrv.SuggestSubscribe(isVipFunction(action_type));
                }
                if (err?.message === USER_TOKEN_UPDATED) {
                    subscribeSrv.SuggestResign();
                }
                reject(err?.message);
                return;
            }
            if (response.getErrorCode() !== 0) {
                console.error(response.getMessage());
                if (response.getMessage() === LIMITATION_USE_UP) {
                    subscribeSrv.SuggestSubscribe(isVipFunction(action_type));
                }
                reject(response.getMessage());
                return;
            }

            switch (user_type) {
                case USER_TYPE.UNREGISTERED_USER:
                case USER_TYPE.UNVERIFIED_USER:
                case USER_TYPE.REGISTERED_USER:
                    updateUsageLimits(action_type, BASIC_USAGE_LIMITS);
                    break;
                case USER_TYPE.FREE_TRIAL_USER:
                    updateUsageLimits(action_type, FREE_TRIAL_USAGE_LIMITS);
                    break;
                case USER_TYPE.PREMIUM_USER:
                case USER_TYPE.INVALID_USER:
                default:
                    break;
            }

            reslove(response);
            return;
        });
    });
}

function isVipFunction(action_type: number): boolean {
    return action_type === ACTION_TYPE.ACTION_TYPE_ANIMATE ||
        action_type === ACTION_TYPE.ACTION_TYPE_REMOVE_BACKGROUND;
}

function checkUsageLimits(action_type: number, default_usage_limit: RemainingUsageAmount): boolean {
    // get usage limits from cookie
    let usage = userInfoSrv.GetUsage() ?? default_usage_limit;

    // check whether use up
    switch (action_type) {
        case ACTION_TYPE.ACTION_TYPE_COMPRESSION:
            return usage.Compress > 0;
        case ACTION_TYPE.ACTION_TYPE_CONVERT:
            return usage.Convert > 0;
        case ACTION_TYPE.ACTION_TYPE_RESIZE:
            return usage.Resize > 0;
        case ACTION_TYPE.ACTION_TYPE_ANIMATE:
            return usage.Animate > 0;
        case ACTION_TYPE.ACTION_TYPE_COLOURIZE:
            return usage.Colourize > 0;
        case ACTION_TYPE.ACTION_TYPE_ENHANCE_DEFINITION:
            return usage.Enhance > 0;
        case ACTION_TYPE.ACTION_TYPE_ENLARGE_SIZE:
            return usage.Enlarge > 0;
        case ACTION_TYPE.ACTION_TYPE_REMOVE_BACKGROUND:
            return usage.Remove > 0;
        case ACTION_TYPE.ACTION_TYPE_RESTORE_STRETCH:
            return usage.Restore > 0;
    }
    return false;
}

function updateUsageLimits(action_type: number, default_usage_limit: RemainingUsageAmount) {
    // get usage limits from cookie
    let usage = userInfoSrv.GetUsage() ?? default_usage_limit;

    // update usage limits
    switch (action_type) {
        case ACTION_TYPE.ACTION_TYPE_COMPRESSION:
            usage.Compress--;
            break;
        case ACTION_TYPE.ACTION_TYPE_CONVERT:
            usage.Convert--;
            break;
        case ACTION_TYPE.ACTION_TYPE_RESIZE:
            usage.Resize--;
            break;
        case ACTION_TYPE.ACTION_TYPE_ANIMATE:
            usage.Animate--;
            break;
        case ACTION_TYPE.ACTION_TYPE_COLOURIZE:
            usage.Colourize--;
            break;
        case ACTION_TYPE.ACTION_TYPE_ENHANCE_DEFINITION:
            usage.Enhance--;
            break;
        case ACTION_TYPE.ACTION_TYPE_ENLARGE_SIZE:
            usage.Enlarge--;
            break;
        case ACTION_TYPE.ACTION_TYPE_REMOVE_BACKGROUND:
            usage.Remove--;
            break;
        case ACTION_TYPE.ACTION_TYPE_RESTORE_STRETCH:
            usage.Restore--;
            break;
    }

    // set usage limits cookie
    userInfoSrv.SaveUsage(usage);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

let UserManagerIntercepteInvoke = (method: any, request: any) => {
    return new Promise<any>((reslove, reject) => {
        method(request, getMetadata(), (err: any, response: any) => {
            if (!!err || !response) {
                console.error(err?.message);
                reject(err?.message);
                return;
            }
            if (response.getErrorCode() !== 0) {
                console.error(response.getMessage());
                reject(response.getMessage());
                return;
            }
            reslove(response);
            return;
        });
    });
}

export {
    ImgProcessorIntercepteInvoke,
    UserManagerIntercepteInvoke,
};
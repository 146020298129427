import { HeartTwoTone, WechatOutlined, WeiboOutlined } from '@ant-design/icons';
import React from 'react';
import { AiOutlineTwitter } from 'react-icons/ai';
import { FaRedditAlien } from 'react-icons/fa';
import { RiFacebookFill } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import './shareDonate.css';

export default class ShareDonate extends React.Component<{}, {}> {
    render() {
        return (
            <div className="share-donate">
                <div className="share-title">
                    <FormattedMessage id="thank-share" />
                </div>
                <div className="share-btns">
                    <WeiboOutlined className="share-btn" />
                    <WechatOutlined className="share-btn" />
                </div>
                <div className="donate-title">
                    <FormattedMessage id="thank-donate" />&nbsp;<HeartTwoTone twoToneColor="#eb2f96" />
                </div>
                <div className="donate-btns">
                    <RiFacebookFill className="donate-btn" />
                    <AiOutlineTwitter className="donate-btn" />
                    <FaRedditAlien className="donate-btn" />
                </div>
            </div>
        );
    }
}
import { DoubleRightOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Tooltip } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { BsFillQuestionCircleFill } from "react-icons/bs";
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import FQA from '../../../components/fqa/fqa';
import CommonHeader from '../../../components/header/header';
import { FQAData } from '../../../models/fqaModel';
import './user-premium.css';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

class UserPremium extends React.Component<any, any> {
    onFreeGetStarted = () => {
        window.location.href = '/';
    };

    onFreeTrial = () => {
        //to login
    };

    onGoPremium = () => {
        //to charge
    };

    render() {
        const intl = this.props.intl;
        let fqa: FQAData = {
            title: intl.formatMessage({ id: 'fqa' }),
            listData: [
                {
                    question: intl.formatMessage({ id: 'fqa-premium-1-question' }),
                    answer: intl.formatMessage({ id: 'fqa-premium-1-anwser' }),
                },
                {
                    question: intl.formatMessage({ id: 'fqa-premium-2-question' }),
                    answer: intl.formatMessage({ id: 'fqa-premium-2-anwser' }),
                },
                {
                    question: intl.formatMessage({ id: 'fqa-premium-3-question' }),
                    answer: intl.formatMessage({ id: 'fqa-premium-3-anwser' }),
                },
                {
                    question: intl.formatMessage({ id: 'fqa-premium-4-question' }),
                    answer: intl.formatMessage({ id: 'fqa-premium-4-anwser' }),
                },
            ],
        };
        return (
            <div>
                <CommonHeader></CommonHeader>
                <div className="user-premium-title"><FormattedMessage id='upgrade' /></div>
                <div className="user-premium">
                    <div className="user-level-item user-level-normal">
                        <div className='user-level-normal-title'><FormattedMessage id='free' /></div>
                        <div className='price-text'><FormattedMessage id='US$0' /></div>
                        <div className="user-level-ctl">
                            <Button size="large" className='free-get-started' onClick={this.onFreeGetStarted}><FormattedMessage id='free-get-started' /></Button>
                        </div>
                        <div className='user-level-intro'><FormattedMessage id='free-features' /></div>
                        <div className='user-level-feature-item'>
                            <FaCheckCircle style={{ color: '#49cd86', fontWeight: 'bolder' }} />&nbsp;
                            <FormattedMessage id='limited-access-tools' />
                            <Tooltip placement="rightTop" title={this.renderLimitedTools()} overlayInnerStyle={{ width: '280px' }} color='white'>
                                <Button type="link" icon={<BsFillQuestionCircleFill />} />
                            </Tooltip>
                        </div>
                        <Divider dashed style={{ margin: '2px', borderColor: 'grey' }} />
                        <div className='user-level-feature-item'>
                            <FaCheckCircle style={{ color: '#49cd86', fontWeight: 'bolder' }} />&nbsp;
                            <FormattedMessage id='limited-process-count' />
                            <Tooltip placement="rightTop" title={this.renderLimitedProcessCount()} overlayInnerStyle={{ width: '330px' }} color='white'>
                                <Button type="link" icon={<BsFillQuestionCircleFill />} />
                            </Tooltip>
                        </div>
                        <Divider dashed style={{ margin: '2px', borderColor: 'grey' }} />
                        <div className='user-level-feature-item'>
                            <FaCheckCircle style={{ color: '#49cd86', fontWeight: 'bolder' }} />&nbsp;
                            <FormattedMessage id='limited-process-batch' />
                            <Tooltip placement="rightTop" title={this.renderLimitedProcessBatch()} overlayInnerStyle={{ width: '300px' }} color='white'>
                                <Button type="link" icon={<BsFillQuestionCircleFill />} />
                            </Tooltip>
                        </div>
                        <Divider dashed style={{ margin: '2px', borderColor: 'grey' }} />
                        <div className='user-level-feature-item'>
                            <FaCheckCircle style={{ color: '#49cd86', fontWeight: 'bolder' }} />&nbsp;
                            <FormattedMessage id='free-works-anywhere' />
                        </div>
                        <Divider dashed style={{ margin: '2px', borderColor: 'grey' }} />

                    </div>
                    <DoubleRightOutlined style={{ fontSize: '60px', color: 'gray' }} />
                    <div className="user-level-item user-level-premium">
                        <div className='user-level-premium-title'><FormattedMessage id='premium' /></div>
                        <div>
                            <span className='price-text'><FormattedMessage id='US$9.9' /></span>&nbsp;/&nbsp;<FormattedMessage id='month' />
                            &nbsp;&nbsp;<FormattedMessage id='or' />&nbsp;&nbsp;
                            <span className='price-text'><FormattedMessage id='US$99.9' /></span>&nbsp;/&nbsp;<FormattedMessage id='year' />
                        </div>
                        <div className="user-level-ctl">
                            <Tooltip placement="bottom" title={this.rendFreeTrial()}>
                                <Button size="large" className='premium-to-try' onClick={this.onFreeTrial}><FormattedMessage id='free-trial' /></Button>
                            </Tooltip>
                            <Button type="primary" size="large" className='premium-to-charge' onClick={this.onGoPremium}><FormattedMessage id='go-premium' /></Button>
                        </div>
                        <div className='user-level-intro'><FormattedMessage id='premium-features' /></div>
                        <div className='user-level-feature-item'>
                            <FaCheckCircle style={{ color: '#49cd86', fontWeight: 'bolder' }} />&nbsp;
                            <FormattedMessage id='full-access-tools' />
                            <Tooltip placement="rightTop" title={this.renderUnlimitedTools()} overlayInnerStyle={{ width: '280px' }} color='white'>
                                <Button type="link" icon={<BsFillQuestionCircleFill />} />
                            </Tooltip>
                        </div>
                        <Divider dashed style={{ margin: '2px', borderColor: 'grey' }} />
                        <div className='user-level-feature-item'>
                            <FaCheckCircle style={{ color: '#49cd86', fontWeight: 'bolder' }} />&nbsp;
                            <FormattedMessage id='unlimited-process-count' />
                            <Tooltip placement="rightTop" title={this.renderUnlimitedProcessCount()} overlayInnerStyle={{ width: '330px' }} color='white'>
                                <Button type="link" icon={<BsFillQuestionCircleFill />} />
                            </Tooltip>
                        </div>
                        <Divider dashed style={{ margin: '2px', borderColor: 'grey' }} />
                        <div className='user-level-feature-item'>
                            <FaCheckCircle style={{ color: '#49cd86', fontWeight: 'bolder' }} />&nbsp;
                            <FormattedMessage id='maximum process-batch' />
                            <Tooltip placement="rightTop" title={this.renderUnlimitedProcessBatch()} overlayInnerStyle={{ width: '300px' }} color='white'>
                                <Button type="link" icon={<BsFillQuestionCircleFill />} />
                            </Tooltip>
                        </div>
                        <Divider dashed style={{ margin: '2px', borderColor: 'grey' }} />
                        <div className='user-level-feature-item'>
                            <FaCheckCircle style={{ color: '#49cd86', fontWeight: 'bolder' }} />&nbsp;
                            <FormattedMessage id='no-ads' />
                        </div>
                        <Divider dashed style={{ margin: '2px', borderColor: 'grey' }} />
                        <div className='user-level-feature-item'>
                            <FaCheckCircle style={{ color: '#49cd86', fontWeight: 'bolder' }} />&nbsp;
                            <FormattedMessage id='current-support-in24h' />
                        </div>
                        <Divider dashed style={{ margin: '2px', borderColor: 'grey' }} />
                    </div>
                </div>
                <FQA fqaData={fqa}></FQA>
                <Footer style={{ textAlign: 'center' }}>ImgPro ©2021~2026 Created by BarretX</Footer>
            </div>
        );
    }

    rendFreeTrial() {
        return <FormattedMessage id='first-login-free-trial' />;
    }

    renderLimitedTools() {
        const columns = [
            {
                title: <FormattedMessage id='tool' />,
                dataIndex: 'tool',
            },
            {
                title: <FormattedMessage id='availability' />,
                dataIndex: 'availability',
            }
        ];
        const data = [
            {
                key: '1',
                tool: <FormattedMessage id='compress' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '2',
                tool: <FormattedMessage id='enlarge-size' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '3',
                tool: <FormattedMessage id='convert' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '4',
                tool: <FormattedMessage id='resize' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '5',
                tool: <FormattedMessage id='restore-stretching' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '6',
                tool: <FormattedMessage id='animate' />,
                availability: <FaTimesCircle style={{ color: 'red', fontWeight: 'bolder' }} />,
            },
            {
                key: '7',
                tool: <FormattedMessage id='colourize' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '8',
                tool: <FormattedMessage id='enhance-definition' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '9',
                tool: <FormattedMessage id='remove-background' />,
                availability: <FaTimesCircle style={{ color: 'red', fontWeight: 'bolder' }} />,
            },
        ];
        return <Table columns={columns} dataSource={data} size="small" pagination={false} bordered={true} />;
    }

    renderUnlimitedTools() {
        const columns = [
            {
                title: <FormattedMessage id='tool' />,
                dataIndex: 'tool',
            },
            {
                title: <FormattedMessage id='availability' />,
                dataIndex: 'availability',
            }
        ];
        const data = [
            {
                key: '1',
                tool: <FormattedMessage id='compress' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '2',
                tool: <FormattedMessage id='enlarge-size' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '3',
                tool: <FormattedMessage id='convert' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '4',
                tool: <FormattedMessage id='resize' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '5',
                tool: <FormattedMessage id='restore-stretching' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '6',
                tool: <FormattedMessage id='animate' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '7',
                tool: <FormattedMessage id='colourize' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '8',
                tool: <FormattedMessage id='enhance-definition' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
            {
                key: '9',
                tool: <FormattedMessage id='remove-background' />,
                availability: <FaCheckCircle style={{ color: 'green', fontWeight: 'bolder' }} />,
            },
        ];
        return <Table columns={columns} dataSource={data} size="small" pagination={false} bordered={true} />;
    }

    renderLimitedProcessCount() {
        const columns = [
            {
                title: <FormattedMessage id='tool' />,
                dataIndex: 'tool',
            },
            {
                title: <FormattedMessage id='count-limit' />,
                dataIndex: 'countLimit',
            }
        ];
        const data = [
            {
                key: '1',
                tool: <FormattedMessage id='compress' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '2',
                tool: <FormattedMessage id='enlarge-size' />,
                countLimit: 10,
            },
            {
                key: '3',
                tool: <FormattedMessage id='convert' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '4',
                tool: <FormattedMessage id='resize' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '5',
                tool: <FormattedMessage id='restore-stretching' />,
                countLimit: 10,
            },
            {
                key: '6',
                tool: <FormattedMessage id='animate' />,
                countLimit: 0,
            },
            {
                key: '7',
                tool: <FormattedMessage id='colourize' />,
                countLimit: 5,
            },
            {
                key: '8',
                tool: <FormattedMessage id='enhance-definition' />,
                countLimit: 10,
            },
            {
                key: '9',
                tool: <FormattedMessage id='remove-background' />,
                countLimit: 0,
            },
        ];
        return <Table columns={columns} dataSource={data} size="small" pagination={false} bordered={true} />;
    }

    renderUnlimitedProcessCount() {
        const columns = [
            {
                title: <FormattedMessage id='tool' />,
                dataIndex: 'tool',
            },
            {
                title: <FormattedMessage id='count-limit' />,
                dataIndex: 'countLimit',
            }
        ];
        const data = [
            {
                key: '1',
                tool: <FormattedMessage id='compress' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '2',
                tool: <FormattedMessage id='enlarge-size' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '3',
                tool: <FormattedMessage id='convert' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '4',
                tool: <FormattedMessage id='resize' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '5',
                tool: <FormattedMessage id='restore-stretching' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '6',
                tool: <FormattedMessage id='animate' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '7',
                tool: <FormattedMessage id='colourize' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '8',
                tool: <FormattedMessage id='enhance-definition' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
            {
                key: '9',
                tool: <FormattedMessage id='remove-background' />,
                countLimit: <FormattedMessage id='unlimited' />,
            },
        ];
        return <Table columns={columns} dataSource={data} size="small" pagination={false} bordered={true} />;
    }

    renderLimitedProcessBatch() {
        const columns = [
            {
                title: <FormattedMessage id='tool' />,
                dataIndex: 'tool',
            },
            {
                title: <FormattedMessage id='batch-limit' />,
                dataIndex: 'batchLimit',
            }
        ];
        const data = [
            {
                key: '1',
                tool: <FormattedMessage id='compress' />,
                batchLimit: 30,
            },
            {
                key: '2',
                tool: <FormattedMessage id='enlarge-size' />,
                batchLimit: 1,
            },
            {
                key: '3',
                tool: <FormattedMessage id='convert' />,
                batchLimit: 30,
            },
            {
                key: '4',
                tool: <FormattedMessage id='resize' />,
                batchLimit: 30,
            },
            {
                key: '5',
                tool: <FormattedMessage id='restore-stretching' />,
                batchLimit: 1,
            },
            {
                key: '6',
                tool: <FormattedMessage id='animate' />,
                batchLimit: 0,
            },
            {
                key: '7',
                tool: <FormattedMessage id='colourize' />,
                batchLimit: 1,
            },
            {
                key: '8',
                tool: <FormattedMessage id='enhance-definition' />,
                batchLimit: 1,
            },
            {
                key: '9',
                tool: <FormattedMessage id='remove-background' />,
                batchLimit: 0,
            },
        ];
        return <Table columns={columns} dataSource={data} size="small" pagination={false} bordered={true} />;
    }

    renderUnlimitedProcessBatch() {
        const columns = [
            {
                title: <FormattedMessage id='tool' />,
                dataIndex: 'tool',
            },
            {
                title: <FormattedMessage id='batch-limit' />,
                dataIndex: 'batchLimit',
            }
        ];
        const data = [
            {
                key: '1',
                tool: <FormattedMessage id='compress' />,
                batchLimit: 80,
            },
            {
                key: '2',
                tool: <FormattedMessage id='enlarge-size' />,
                batchLimit: 80,
            },
            {
                key: '3',
                tool: <FormattedMessage id='convert' />,
                batchLimit: 80,
            },
            {
                key: '4',
                tool: <FormattedMessage id='resize' />,
                batchLimit: 80,
            },
            {
                key: '5',
                tool: <FormattedMessage id='restore-stretching' />,
                batchLimit: 80,
            },
            {
                key: '6',
                tool: <FormattedMessage id='animate' />,
                batchLimit: 80,
            },
            {
                key: '7',
                tool: <FormattedMessage id='colourize' />,
                batchLimit: 80,
            },
            {
                key: '8',
                tool: <FormattedMessage id='enhance-definition' />,
                batchLimit: 80,
            },
            {
                key: '9',
                tool: <FormattedMessage id='remove-background' />,
                batchLimit: 80,
            },
        ];
        return <Table columns={columns} dataSource={data} size="small" pagination={false} bordered={true} />;
    }
}

export default injectIntl(UserPremium);
// source: imgpro.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.ImgPro.CommonReply', null, global);
goog.exportSymbol('proto.ImgPro.CommonRequest', null, global);
goog.exportSymbol('proto.ImgPro.CompressRequest', null, global);
goog.exportSymbol('proto.ImgPro.CompressRequest.CompressLevel', null, global);
goog.exportSymbol('proto.ImgPro.ConvertRequest', null, global);
goog.exportSymbol('proto.ImgPro.Image', null, global);
goog.exportSymbol('proto.ImgPro.ImageType', null, global);
goog.exportSymbol('proto.ImgPro.ProErrorCode', null, global);
goog.exportSymbol('proto.ImgPro.ResizeRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ImgPro.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.Image.displayName = 'proto.ImgPro.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.CommonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ImgPro.CommonRequest.repeatedFields_, null);
};
goog.inherits(proto.ImgPro.CommonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.CommonRequest.displayName = 'proto.ImgPro.CommonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.CompressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ImgPro.CompressRequest.repeatedFields_, null);
};
goog.inherits(proto.ImgPro.CompressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.CompressRequest.displayName = 'proto.ImgPro.CompressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.ConvertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ImgPro.ConvertRequest.repeatedFields_, null);
};
goog.inherits(proto.ImgPro.ConvertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.ConvertRequest.displayName = 'proto.ImgPro.ConvertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.ResizeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ImgPro.ResizeRequest.repeatedFields_, null);
};
goog.inherits(proto.ImgPro.ResizeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.ResizeRequest.displayName = 'proto.ImgPro.ResizeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ImgPro.CommonReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ImgPro.CommonReply.repeatedFields_, null);
};
goog.inherits(proto.ImgPro.CommonReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ImgPro.CommonReply.displayName = 'proto.ImgPro.CommonReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: msg.getData_asB64(),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.Image}
 */
proto.ImgPro.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.Image;
  return proto.ImgPro.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.Image}
 */
proto.ImgPro.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.ImageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ImageType type = 1;
 * @return {!proto.ImgPro.ImageType}
 */
proto.ImgPro.Image.prototype.getType = function() {
  return /** @type {!proto.ImgPro.ImageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.ImageType} value */
proto.ImgPro.Image.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.ImgPro.Image.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.ImgPro.Image.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.ImgPro.Image.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/** @param {!(string|Uint8Array)} value */
proto.ImgPro.Image.prototype.setData = function(value) {
  jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.ImgPro.Image.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.ImgPro.Image.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ImgPro.CommonRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.CommonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.CommonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.CommonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.CommonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.ImgPro.Image.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.CommonRequest}
 */
proto.ImgPro.CommonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.CommonRequest;
  return proto.ImgPro.CommonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.CommonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.CommonRequest}
 */
proto.ImgPro.CommonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ImgPro.Image;
      reader.readMessage(value,proto.ImgPro.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.CommonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.CommonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.CommonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.CommonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ImgPro.Image.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Image images = 1;
 * @return {!Array<!proto.ImgPro.Image>}
 */
proto.ImgPro.CommonRequest.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.ImgPro.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ImgPro.Image, 1));
};


/** @param {!Array<!proto.ImgPro.Image>} value */
proto.ImgPro.CommonRequest.prototype.setImagesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ImgPro.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ImgPro.Image}
 */
proto.ImgPro.CommonRequest.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ImgPro.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.ImgPro.CommonRequest.prototype.clearImagesList = function() {
  this.setImagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ImgPro.CompressRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.CompressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.CompressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.CompressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.CompressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.ImgPro.Image.toObject, includeInstance),
    level: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.CompressRequest}
 */
proto.ImgPro.CompressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.CompressRequest;
  return proto.ImgPro.CompressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.CompressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.CompressRequest}
 */
proto.ImgPro.CompressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ImgPro.Image;
      reader.readMessage(value,proto.ImgPro.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 2:
      var value = /** @type {!proto.ImgPro.CompressRequest.CompressLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.CompressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.CompressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.CompressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.CompressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ImgPro.Image.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.ImgPro.CompressRequest.CompressLevel = {
  SLIGHT: 0,
  NORMAL: 1,
  STRONG: 2
};

/**
 * repeated Image images = 1;
 * @return {!Array<!proto.ImgPro.Image>}
 */
proto.ImgPro.CompressRequest.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.ImgPro.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ImgPro.Image, 1));
};


/** @param {!Array<!proto.ImgPro.Image>} value */
proto.ImgPro.CompressRequest.prototype.setImagesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ImgPro.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ImgPro.Image}
 */
proto.ImgPro.CompressRequest.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ImgPro.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.ImgPro.CompressRequest.prototype.clearImagesList = function() {
  this.setImagesList([]);
};


/**
 * optional CompressLevel level = 2;
 * @return {!proto.ImgPro.CompressRequest.CompressLevel}
 */
proto.ImgPro.CompressRequest.prototype.getLevel = function() {
  return /** @type {!proto.ImgPro.CompressRequest.CompressLevel} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.ImgPro.CompressRequest.CompressLevel} value */
proto.ImgPro.CompressRequest.prototype.setLevel = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ImgPro.ConvertRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.ConvertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.ConvertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.ConvertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ConvertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.ImgPro.Image.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.ConvertRequest}
 */
proto.ImgPro.ConvertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.ConvertRequest;
  return proto.ImgPro.ConvertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.ConvertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.ConvertRequest}
 */
proto.ImgPro.ConvertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ImgPro.Image;
      reader.readMessage(value,proto.ImgPro.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 2:
      var value = /** @type {!proto.ImgPro.ImageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.ConvertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.ConvertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.ConvertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ConvertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ImgPro.Image.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated Image images = 1;
 * @return {!Array<!proto.ImgPro.Image>}
 */
proto.ImgPro.ConvertRequest.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.ImgPro.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ImgPro.Image, 1));
};


/** @param {!Array<!proto.ImgPro.Image>} value */
proto.ImgPro.ConvertRequest.prototype.setImagesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ImgPro.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ImgPro.Image}
 */
proto.ImgPro.ConvertRequest.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ImgPro.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.ImgPro.ConvertRequest.prototype.clearImagesList = function() {
  this.setImagesList([]);
};


/**
 * optional ImageType type = 2;
 * @return {!proto.ImgPro.ImageType}
 */
proto.ImgPro.ConvertRequest.prototype.getType = function() {
  return /** @type {!proto.ImgPro.ImageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.ImgPro.ImageType} value */
proto.ImgPro.ConvertRequest.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ImgPro.ResizeRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.ResizeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.ResizeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.ResizeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ResizeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.ImgPro.Image.toObject, includeInstance),
    width: jspb.Message.getFieldWithDefault(msg, 2, 0),
    height: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.ResizeRequest}
 */
proto.ImgPro.ResizeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.ResizeRequest;
  return proto.ImgPro.ResizeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.ResizeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.ResizeRequest}
 */
proto.ImgPro.ResizeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ImgPro.Image;
      reader.readMessage(value,proto.ImgPro.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.ResizeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.ResizeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.ResizeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.ResizeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ImgPro.Image.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * repeated Image images = 1;
 * @return {!Array<!proto.ImgPro.Image>}
 */
proto.ImgPro.ResizeRequest.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.ImgPro.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ImgPro.Image, 1));
};


/** @param {!Array<!proto.ImgPro.Image>} value */
proto.ImgPro.ResizeRequest.prototype.setImagesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ImgPro.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ImgPro.Image}
 */
proto.ImgPro.ResizeRequest.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ImgPro.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.ImgPro.ResizeRequest.prototype.clearImagesList = function() {
  this.setImagesList([]);
};


/**
 * optional uint32 width = 2;
 * @return {number}
 */
proto.ImgPro.ResizeRequest.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.ImgPro.ResizeRequest.prototype.setWidth = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 height = 3;
 * @return {number}
 */
proto.ImgPro.ResizeRequest.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.ImgPro.ResizeRequest.prototype.setHeight = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ImgPro.CommonReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ImgPro.CommonReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ImgPro.CommonReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ImgPro.CommonReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.CommonReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.ImgPro.Image.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ImgPro.CommonReply}
 */
proto.ImgPro.CommonReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ImgPro.CommonReply;
  return proto.ImgPro.CommonReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ImgPro.CommonReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ImgPro.CommonReply}
 */
proto.ImgPro.CommonReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ImgPro.ProErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.ImgPro.Image;
      reader.readMessage(value,proto.ImgPro.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ImgPro.CommonReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ImgPro.CommonReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ImgPro.CommonReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ImgPro.CommonReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ImgPro.Image.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProErrorCode error_code = 1;
 * @return {!proto.ImgPro.ProErrorCode}
 */
proto.ImgPro.CommonReply.prototype.getErrorCode = function() {
  return /** @type {!proto.ImgPro.ProErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.ImgPro.ProErrorCode} value */
proto.ImgPro.CommonReply.prototype.setErrorCode = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ImgPro.CommonReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.ImgPro.CommonReply.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Image images = 3;
 * @return {!Array<!proto.ImgPro.Image>}
 */
proto.ImgPro.CommonReply.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.ImgPro.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ImgPro.Image, 3));
};


/** @param {!Array<!proto.ImgPro.Image>} value */
proto.ImgPro.CommonReply.prototype.setImagesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ImgPro.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ImgPro.Image}
 */
proto.ImgPro.CommonReply.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ImgPro.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.ImgPro.CommonReply.prototype.clearImagesList = function() {
  this.setImagesList([]);
};


/**
 * @enum {number}
 */
proto.ImgPro.ProErrorCode = {
  PRO_SUCCEED: 0,
  PRO_UNSUBSCRIBED: 1,
  PRO_INVALID_INPUT: 2,
  PRO_OTHERS: 4
};

/**
 * @enum {number}
 */
proto.ImgPro.ImageType = {
  JPEG: 0,
  PNG: 1,
  GIF: 2,
  TIFF: 3,
  BMP: 4
};

goog.object.extend(exports, proto.ImgPro);

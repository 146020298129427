import { Layout, Dropdown, Menu, message, Modal } from 'antd';
import React from 'react';
import './header.css';
import imgLogo from '../../assets/logo.png';
import { UserOutlined, GlobalOutlined, LoginOutlined } from '@ant-design/icons';
import i18nSrv from '../../services/i18nSrv';
import { FormattedMessage, injectIntl } from 'react-intl';
import usermanager from '../../services/usermanager';
import SignIn from '../signIn/signIn';
import { PasswordSignInInfo, RegisterInfo } from '../../models/authModel';
import userInfoSrv from '../../services/userInfoSrv';
import subscribeSrv from '../../services/subscribeSrv';
import { SUGGEST_TYPE } from '../../models/usageModel';

const { Header } = Layout;

class CommonHeader extends React.Component<any, any> {
    state = {
        signInModalVisible: false,
        isSigned: false,
        username: "",
    };

    updateUserInfo() {
        let isSigned = userInfoSrv.IsSigned();
        let user = userInfoSrv.GetUser();
        this.setState({
            isSigned: isSigned,
            username: user?.username,
        });
    }

    componentDidMount() {
        this.updateUserInfo();
        subscribeSrv.subscribeSuggestEvent(this.showSuggestModal);
    }

    get toolItems() {
        return [
            "compress",
            "enlarge-size",
            "convert",
            "resize",
            "restore-stretching",
            "animate",
            "colourize",
            "enhance-definition",
            "remove-background"
        ];
    }

    get selectedKeys() {
        const location = window.location.pathname.slice(1);
        return [location];
    }

    onChangeLocale = (e: any) => {
        i18nSrv.ChangeLocale(e.key);
    };

    localeMenu: any = (
        <Menu onClick={this.onChangeLocale}>
            <Menu.Item key="en">English</Menu.Item>
            <Menu.Item key="zh">简体中文</Menu.Item>
        </Menu>
    );

    onLogout = async () => {
        await usermanager.Logout();
        this.updateUserInfo();
    }

    userMenu: any = (
        <Menu>
            <Menu.Item key="info" onClick={() => { window.location.href = "/user" }}><FormattedMessage id='account' /></Menu.Item>
            <Menu.Item key="premium" onClick={() => { window.location.href = "/user/premium" }}><FormattedMessage id='upgrade' /></Menu.Item>
            <Menu.Divider />
            <Menu.Item key="exit" onClick={this.onLogout}><FormattedMessage id='logout' /></Menu.Item>
        </Menu>
    );

    closeSignIn = () => {
        this.setState({ signInModalVisible: false });
    }

    onRegister = async (registerInfo: RegisterInfo) => {
        try {
            await usermanager.Register(registerInfo);
            this.updateUserInfo();
            this.closeSignIn();
        } catch (error) {
            message.error(error as string);
        }
    }

    onSignInWithPassword = async (signInWithPasswordInfo: PasswordSignInInfo) => {
        try {
            await usermanager.LoginWithPassword(signInWithPasswordInfo);
            this.updateUserInfo();
            this.closeSignIn();
        } catch (error) {
            message.error(error as string);
        }
    }

    render() {
        return (
            <Header className="header">
                <Menu theme="dark" mode="horizontal" selectedKeys={this.selectedKeys} className="header-menu">
                    {
                        this.toolItems.map((value) => {
                            return <Menu.Item key={value} onClick={() => {
                                window.location.href = "/" + value;
                            }}>
                                {<FormattedMessage id={value} />}
                            </Menu.Item>;
                        })
                    }
                </Menu>

                <img src={imgLogo} alt="" className="header-logo" onClick={() => {
                    window.location.href = "/"
                }}></img>

                <div className="header-user">
                    <Dropdown overlay={this.localeMenu} placement="bottomCenter">
                        <button className="ant-dropdown-link locale-dropdown-btn" onClick={e => e.preventDefault()}>
                            <GlobalOutlined />
                        </button>
                    </Dropdown>
                    {
                        this.state.isSigned ?
                            (
                                <Dropdown overlay={this.userMenu} placement="bottomCenter">
                                    <div className="header-user-link">
                                        <UserOutlined /> {this.state.username}
                                    </div>
                                </Dropdown>

                            ) :
                            (
                                <div className="header-user-link" onClick={() => { this.setState({ signInModalVisible: true }) }}>
                                    <LoginOutlined /> <FormattedMessage id="SignIn" />
                                </div>
                            )
                    }
                </div>
                <SignIn
                    visible={this.state.signInModalVisible}
                    close={this.closeSignIn}
                    register={this.onRegister}
                    signInWithPassword={this.onSignInWithPassword}
                />
            </Header>
        );
    }

    showSuggestModal = (modal_type: number) => {
        const intl = this.props.intl;
        switch (modal_type) {
            case SUGGEST_TYPE.SUGGEST_TYPE_PREMIUM_FIRST_LOGIN_TO_TRIAL:
                Modal.confirm({
                    title: intl.formatMessage({ id: 'suggest-premium-function-first-login-free-trial' }),
                    okText: intl.formatMessage({ id: 'to-sigin' }),
                    cancelText: intl.formatMessage({ id: 'to-giveup' }),
                    onOk: () => {
                        this.setState({ signInModalVisible: true })
                    },
                    onCancel: () => {
                        window.location.href = "/";
                    },
                });
                break;
            case SUGGEST_TYPE.SUGGEST_TYPE_USEUP_FIRST_LOGIN_TO_TRIAL:
                Modal.confirm({
                    title: intl.formatMessage({ id: 'suggest-useup-first-login-free-trial' }),
                    okText: intl.formatMessage({ id: 'to-sigin' }),
                    cancelText: intl.formatMessage({ id: 'to-giveup' }),
                    onOk: () => {
                        this.setState({ signInModalVisible: true })
                    },
                    onCancel: () => {
                        window.location.href = "/";
                    },
                });
                break;
            case SUGGEST_TYPE.SUGGEST_TYPE_PREMIUM_NEED_VERIFY_EMAIL:
                Modal.confirm({
                    title: intl.formatMessage({ id: 'suggest-premium-function-verify-email' }),
                    okText: intl.formatMessage({ id: 'to-verify-email' }),
                    cancelText: intl.formatMessage({ id: 'to-giveup' }),
                    onOk: () => {
                        //TODO: to verify email
                    },
                    onCancel: () => {
                        window.location.href = "/";
                    },
                });
                break;
            case SUGGEST_TYPE.SUGGEST_TYPE_USEUP_NEED_VERIFY_EMAIL:
                Modal.confirm({
                    title: intl.formatMessage({ id: 'suggest-useup-verify-email' }),
                    okText: intl.formatMessage({ id: 'to-verify-email' }),
                    cancelText: intl.formatMessage({ id: 'to-giveup' }),
                    onOk: () => {
                        //TODO: to verify email
                    },
                    onCancel: () => {
                        window.location.href = "/";
                    },
                });
                break;
            case SUGGEST_TYPE.SUGGEST_TYPE_PREMIUM_NEED_SUBSCRIBE:
                Modal.confirm({
                    title: intl.formatMessage({ id: 'suggest-premium-function-to-subscribe' }),
                    okText: intl.formatMessage({ id: 'to-subscribe' }),
                    cancelText: intl.formatMessage({ id: 'to-giveup' }),
                    onOk: () => {
                        //TODO: to subscribe
                    },
                    onCancel: () => {
                        window.location.href = "/";
                    },
                });
                break;
            case SUGGEST_TYPE.SUGGEST_TYPE_USEUP_NEED_SUBSCRIBE:
                Modal.confirm({
                    title: intl.formatMessage({ id: 'suggest-useup-to-subscribe' }),
                    okText: intl.formatMessage({ id: 'to-subscribe' }),
                    cancelText: intl.formatMessage({ id: 'to-giveup' }),
                    onOk: () => {
                        //TODO: to subscribe
                    },
                    onCancel: () => {
                        window.location.href = "/";
                    },
                });
                break;
            case SUGGEST_TYPE.SUGGEST_TYPE_TRIAL_USEUP_NEED_SUBSCRIBE:
                Modal.confirm({
                    title: intl.formatMessage({ id: 'suggest-useup-trial-to-subscribe' }),
                    okText: intl.formatMessage({ id: 'to-subscribe' }),
                    cancelText: intl.formatMessage({ id: 'to-giveup' }),
                    onOk: () => {
                        //TODO: to subscribe
                    },
                    onCancel: () => {
                        window.location.href = "/";
                    },
                });
                break;
            case SUGGEST_TYPE.SUGGEST_TYPE_NEED_RESIGN:
                usermanager.ClearLoginInfo();
                this.updateUserInfo();
                Modal.confirm({
                    title: intl.formatMessage({ id: 'login-other-place' }),
                    okText: intl.formatMessage({ id: 'to-sigin' }),
                    cancelText: intl.formatMessage({ id: 'to-giveup' }),
                    onOk: () => {
                        this.setState({ signInModalVisible: true })
                    },
                    onCancel: () => {
                    },
                });
                break;
            default:
                break;
        }
    }
}

export default injectIntl(CommonHeader);
import React from 'react';
import './toolList.css';
import ToolItem from './toolItem/toolItem';
import { FormattedMessage } from 'react-intl';
import compressLogo from '../../assets/compress.svg';
import enlargeLogo from '../../assets/enlarge.svg';
import convertLogo from '../../assets/convert.svg';
import resizeLogo from '../../assets/resize.svg';
import restoreLogo from '../../assets/restore.svg';
import animateLogo from '../../assets/animate.svg';
import colourizeLogo from '../../assets/colourize.svg';
import enhanceLogo from '../../assets/enhance.svg';
import removeLogo from '../../assets/remove.svg';
import { Tool } from '../../models/toolModel';

export default class ToolList extends React.Component {
    toolListData: Array<Tool> = [
        {
            imgSrc: compressLogo,
            title: <FormattedMessage id="compress" />,
            describe: <FormattedMessage id="compress-describe" />,
            url: "/compress"
        },
        {
            imgSrc: enlargeLogo,
            title: <FormattedMessage id="enlarge-size" />,
            describe: <FormattedMessage id="enlarge-size-describe" />,
            url: "/enlarge-size"
        },
        {
            imgSrc: convertLogo,
            title: <FormattedMessage id="convert" />,
            describe: <FormattedMessage id="convert-describe" />,
            url: "/convert"
        },
        {
            imgSrc: resizeLogo,
            title: <FormattedMessage id="resize" />,
            describe: <FormattedMessage id="resize-describe" />,
            url: "/resize"
        },
        {
            imgSrc: restoreLogo,
            title: <FormattedMessage id="restore-stretching" />,
            describe: <FormattedMessage id="restore-stretching-describe" />,
            url: "/restore-stretching"
        },
        {
            imgSrc: animateLogo,
            title: <FormattedMessage id="animate" />,
            describe: <FormattedMessage id="animate-describe" />,
            url: "/animate"
        },
        {
            imgSrc: colourizeLogo,
            title: <FormattedMessage id="colourize" />,
            describe: <FormattedMessage id="colourize-describe" />,
            url: "/colourize"
        },
        {
            imgSrc: enhanceLogo,
            title: <FormattedMessage id="enhance-definition" />,
            describe: <FormattedMessage id="enhance-definition-describe" />,
            url: "/enhance-definition"
        },
        {
            imgSrc: removeLogo,
            title: <FormattedMessage id="remove-background" />,
            describe: <FormattedMessage id="remove-background-describe" />,
            url: "/remove-background"
        }
    ];
    render() {
        return (
            <div className="tool-list">
                {
                    this.toolListData.map((data, index) => {
                        return <ToolItem data={data} key={index}></ToolItem>;
                    })
                }
            </div>
        );
    }
}
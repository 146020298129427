import { User } from "../models/authModel";
import cookieSrv from "./cookieSrv";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { RemainingUsageAmount, USER_TYPE } from "../models/usageModel";
import { UserType } from "../grpc/authmanager_pb";


class UserInfoService {
    IsSigned(): boolean {
        let user_auth_cookie = cookieSrv.getCookie("user_auth");
        let user_info_cookie = cookieSrv.getCookie("user_info");
        return !!user_auth_cookie &&
            user_auth_cookie.length > 10 &&
            !!user_info_cookie &&
            user_info_cookie.length > 10;
    }

    SaveUser(user: User) {
        let userStr = this.encode(user);
        cookieSrv.setCookie("user_info", userStr, 60 * 60 * 24);
    }

    GetUser(): User | null {
        let userStr = cookieSrv.getCookie("user_info");
        if (!!userStr && userStr.length > 10) {
            return this.decode(userStr);
        }
        return null
    }

    async SaveUserFingerPrint(): Promise<void> {
        let fingerPrint = cookieSrv.getCookie("user_fingerprint");
        if (!!fingerPrint && fingerPrint.length > 10) {
            return;
        }

        const fpPromise = FingerprintJS.load();
        const fp = await fpPromise;
        const result = await fp.get();
        fingerPrint = result.visitorId;
        cookieSrv.setCookie("user_fingerprint", fingerPrint, 60 * 60 * 24 * 30);
    }

    GetUserFingerPrint(): string {
        return cookieSrv.getCookie("user_fingerprint");
    }

    CleanUser() {
        cookieSrv.clearCookie("user_info");
    }

    GetUserType(): number {
        let user_info = this.GetUser();
        if (!user_info) {
            return USER_TYPE.UNREGISTERED_USER;
        }

        let user_type = user_info.user_type;
        if (user_type === UserType.PREMIUM) {
            return USER_TYPE.PREMIUM_USER;
        }

        let verify_at = user_info.verify_at;
        if (!verify_at) {
            return USER_TYPE.UNVERIFIED_USER;
        }

        if (verify_at + 24 * 60 * 60 > (new Date()).getTime() / 1000) {
            return USER_TYPE.FREE_TRIAL_USER;
        }

        return USER_TYPE.REGISTERED_USER;
    }

    GetUsage(): RemainingUsageAmount | null {
        let usageStr = cookieSrv.getCookie("usage_limits");
        if (!!usageStr && usageStr.length > 10) {
            return this.decode(usageStr);
        }
        return null
    }

    SaveUsage(usage: RemainingUsageAmount) {
        let str = this.encode(usage);
        cookieSrv.setCookie("usage_limits", str, 60 * 60 * 24);
    }

    encode(object: any): string {
        let json = JSON.stringify(object);
        return window.btoa(json);
    }

    decode(str: string): any {
        let json = window.atob(str);
        return JSON.parse(json);
    }
}

let userInfoSrv = new UserInfoService();
export default userInfoSrv;
import {
    AuthClientRequest,
    AuthClientResponse,
    RegisterUserRequest,
    AuthUserRequest,
    // ModifyUserRequest,
    // ModifyUserResponse,
    // UserSubscribeRequest,
    // UserSubscribeResponse,
    ExitRequest,
    AuthType,
    UserInfo,
} from '../grpc/authmanager_pb';
import usermanagerSvc from "../grpcSrv/usermanagerSrv";
import cookieSrv from './cookieSrv';
import encryptSrv from './encryptSrv';
import { PasswordSignInInfo, RegisterInfo, User } from '../models/authModel';
import userInfoSrv from './userInfoSrv';
const client_auth_info = {
    client_id: "3991209123",
    client_secret: "fsjfljnqweo2983nhkjznx7e3%*z",
    key: "skjf42xrf3s",
};

class UserManager {
    async AuthClient(): Promise<boolean> {
        let client_auth_cookie = cookieSrv.getCookie("client_auth")
        if (!!client_auth_cookie && client_auth_cookie.length > 10) {
            return true;
        }

        let request = new AuthClientRequest();
        request.setClientId(client_auth_info.client_id);
        let dynamicSecret = encryptSrv.AESEncrypt(client_auth_info.client_id + client_auth_info.client_secret + (new Date()).getTime().toString(), client_auth_info.key, 16);
        request.setDynamicSecret(dynamicSecret);

        let response: AuthClientResponse;
        try {
            response = await usermanagerSvc.AuthClient(request);
        } catch (error) {
            console.error(error as string);
            cookieSrv.clearCookie("client_auth");
            return false;
        }

        let clientToken = response.getClientToken();
        let clientTokenExpiresIn = response.getClientExpiresIn();
        cookieSrv.setCookie("client_auth", clientToken, clientTokenExpiresIn);
        return true;
    }

    async LoginWithPassword(info: PasswordSignInInfo): Promise<User> {
        // login 
        let request = new AuthUserRequest();
        request.setAuthType(AuthType.AUTH_USER_PASSWORD);
        request.setUsernameOrEmail(info.username_or_email);
        request.setPassword(info.password);

        let response = await usermanagerSvc.AuthUser(request);
        let userInfo = response.getUserInfo();
        if (!userInfo) {
            throw new Error("Empty user info");
        }
        let user: User = {
            user_id: userInfo.getUserId(),
            username: userInfo.getUsername(),
            user_type: userInfo.getUserType(),
            verify_at: userInfo.getVerifyAt(),
            verify_type: userInfo.getVefifyType(),
            name: userInfo.getName(),
            phone: userInfo.getPhone(),
            email: userInfo.getEmail(),
            country: userInfo.getCountry(),
            subscribe_from: userInfo.getSubscribeFrom(),
            subscribe_to: userInfo.getSubscribeTo(),
            created_at: userInfo.getCreatedAt(),
            updated_at: userInfo.getUpdatedAt(),
            last_login_ip: userInfo.getLastLoginIp(),
            to_notify: userInfo.getToNotify(),
        };

        // set cookie
        let userToken = response.getUserToken();
        let userTokenExpiresIn = response.getUserExpiresIn();
        cookieSrv.setCookie("user_auth", userToken, userTokenExpiresIn);
        userInfoSrv.SaveUser(user);
        return user;
    }

    async Register(info: RegisterInfo): Promise<User> {
        // register 
        let request = new RegisterUserRequest();
        let userInfo_req = new UserInfo();
        userInfo_req.setUsername(info.username);
        userInfo_req.setEmail(info.email);
        userInfo_req.setPassword(info.password);
        request.setUserInfo(userInfo_req);

        let response = await usermanagerSvc.RegisterUser(request);
        let userInfo_resp = response.getUserInfo();
        if (!userInfo_resp) {
            throw new Error("Empty user info");
        }
        let user: User = {
            user_id: userInfo_resp.getUserId(),
            username: userInfo_resp.getUsername(),
            user_type: userInfo_resp.getUserType(),
            verify_at: userInfo_resp.getVerifyAt(),
            verify_type: userInfo_resp.getVefifyType(),
            name: userInfo_resp.getName(),
            phone: userInfo_resp.getPhone(),
            email: userInfo_resp.getEmail(),
            country: userInfo_resp.getCountry(),
            subscribe_from: userInfo_resp.getSubscribeFrom(),
            subscribe_to: userInfo_resp.getSubscribeTo(),
            created_at: userInfo_resp.getCreatedAt(),
            updated_at: userInfo_resp.getUpdatedAt(),
            last_login_ip: userInfo_resp.getLastLoginIp(),
            to_notify: userInfo_resp.getToNotify(),
        };

        // set cookie
        let userToken = response.getUserToken();
        let userTokenExpiresIn = response.getUserExpiresIn();
        cookieSrv.setCookie("user_auth", userToken, userTokenExpiresIn);
        userInfoSrv.SaveUser(user);
        return user;
    }

    async Logout() {
        // log out
        let request = new ExitRequest();
        await usermanagerSvc.ExitUser(request);

        this.ClearLoginInfo();
    }

    ClearLoginInfo() {
        // clear cookie
        cookieSrv.clearCookie("user_auth");
        userInfoSrv.CleanUser();
    }
}

let usermanager = new UserManager();
export default usermanager;
class CookieService {
    getCookie(name: string): string {
        let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        let arr = document.cookie.match(reg)
        if (!!arr) {
            return unescape(arr[2]);
        }
        return "";
    }

    setCookie(name: string, value: string, seconds: number) {
        seconds = seconds || 0;
        let expires = "";
        if (seconds !== 0) {
            let date = new Date();
            date.setTime(date.getTime() + (seconds * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + escape(value) + expires + "; path=/";
    }

    clearCookie(name: string) {
        let exp = new Date();
        exp.setTime(exp.getTime() - 1);
        let cval = this.getCookie(name);
        if (!!cval) {
            document.cookie = name + "=" + cval + ";expires=" + exp.toUTCString() + "; path=/";
        }
    }
}

let cookieSrv = new CookieService();
export default cookieSrv;
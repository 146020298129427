export interface PasswordSignInInfo {
    username_or_email: string;
    password: string;
};

export interface RegisterInfo {
    username: string;
    email: string;
    password: string;
};

export interface User {
    user_id: number;
    username: string;
    user_type: number;
    verify_at: number;
    verify_type: number;
    name: string;
    phone: string;
    email: string;
    country: string;
    subscribe_from: number;
    subscribe_to: number;
    created_at: number;
    updated_at: number;
    last_login_ip: string;
    to_notify: number;
};

const USER_INVALID = "user_is_invalid";
const PASSWORD_MODIFIED = "password_is_modify";
const USER_DISABLED = "user_disabled";
const USER_TOKEN_UPDATED = "user_redis_token_updated";

export {
    USER_INVALID,
    PASSWORD_MODIFIED,
    USER_DISABLED,
    USER_TOKEN_UPDATED,
};


import React from 'react';
import { Introduction } from '../../../models/instruction';
import './introductionItem.css';

interface IntroductionItemProps{
    data: Introduction;
}

export default class IntroductionItem extends React.Component<IntroductionItemProps, {}> {
    render() {
        return (
            <div className="instruction-item">
                <img className="instruction-item-img" src={this.props.data.imgSrc} alt="" ></img>
                <div className="instruction-item-title" >{this.props.data.title}</div>
                <div className="instruction-item-describe" >{this.props.data.describe}</div>
            </div>
        );
    }
}
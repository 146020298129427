class FileService {
    getBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    getBinary(file: File) {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = () => resolve(reader.result as ArrayBuffer);
            reader.onerror = error => reject(error);
        });
    }

    getImageType(file: File) {
        switch (file.type) {
            case 'image/jpeg':
                return 0;
            case 'image/png':
                return 1;
            case 'image/gif':
                return 2;
            case 'image/tiff':
                return 3;
            case 'image/bmp':
                return 4;
            default:
                return -1;
        }
    }

    getImageExt(type: number) {
        switch (type) {
            case 0:
                return ".jpg";
            case 1:
                return ".png";
            case 2:
                return ".gif";
            case 3:
                return ".tif";
            case 4:
                return ".bmp";
            default:
                return ".jpg";
        }
    }

    isJpgOrPngOrBmp(file: File) {
        return file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png' ||
            file.type === 'image/bmp';
    }

    isJpgOrPngOrGif(file: File) {
        return file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
    }

    isJpgOrPngOrGifOrTiffOrBmp(file: File) {
        return file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif' ||
            file.type === 'image/tiff' ||
            file.type === 'image/bmp';
    }

    isLessThan(file: File, size: number) {
        return file.size / 1024 / 1024 < size;
    }

    isLessThan8M(file: File) {
        return this.isLessThan(file, 8);
    }

    isLessThan2M(file: File) {
        return this.isLessThan(file, 2);
    }
}

let fileSrv = new FileService();
export default fileSrv;
// package: ImgPro
// file: imgpro.proto

var imgpro_pb = require("./imgpro_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ImgProcessor = (function () {
  function ImgProcessor() {}
  ImgProcessor.serviceName = "ImgPro.ImgProcessor";
  return ImgProcessor;
}());

ImgProcessor.Compress = {
  methodName: "Compress",
  service: ImgProcessor,
  requestStream: false,
  responseStream: false,
  requestType: imgpro_pb.CompressRequest,
  responseType: imgpro_pb.CommonReply
};

ImgProcessor.Enlarge = {
  methodName: "Enlarge",
  service: ImgProcessor,
  requestStream: false,
  responseStream: false,
  requestType: imgpro_pb.CommonRequest,
  responseType: imgpro_pb.CommonReply
};

ImgProcessor.Convert = {
  methodName: "Convert",
  service: ImgProcessor,
  requestStream: false,
  responseStream: false,
  requestType: imgpro_pb.ConvertRequest,
  responseType: imgpro_pb.CommonReply
};

ImgProcessor.Resize = {
  methodName: "Resize",
  service: ImgProcessor,
  requestStream: false,
  responseStream: false,
  requestType: imgpro_pb.ResizeRequest,
  responseType: imgpro_pb.CommonReply
};

ImgProcessor.RestoreStretching = {
  methodName: "RestoreStretching",
  service: ImgProcessor,
  requestStream: false,
  responseStream: false,
  requestType: imgpro_pb.CommonRequest,
  responseType: imgpro_pb.CommonReply
};

ImgProcessor.Animate = {
  methodName: "Animate",
  service: ImgProcessor,
  requestStream: false,
  responseStream: false,
  requestType: imgpro_pb.CommonRequest,
  responseType: imgpro_pb.CommonReply
};

ImgProcessor.Colourize = {
  methodName: "Colourize",
  service: ImgProcessor,
  requestStream: false,
  responseStream: false,
  requestType: imgpro_pb.CommonRequest,
  responseType: imgpro_pb.CommonReply
};

ImgProcessor.EnhanceDefinition = {
  methodName: "EnhanceDefinition",
  service: ImgProcessor,
  requestStream: false,
  responseStream: false,
  requestType: imgpro_pb.CommonRequest,
  responseType: imgpro_pb.CommonReply
};

ImgProcessor.RemoveBackground = {
  methodName: "RemoveBackground",
  service: ImgProcessor,
  requestStream: false,
  responseStream: false,
  requestType: imgpro_pb.CommonRequest,
  responseType: imgpro_pb.CommonReply
};

exports.ImgProcessor = ImgProcessor;

function ImgProcessorClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ImgProcessorClient.prototype.compress = function compress(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImgProcessor.Compress, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImgProcessorClient.prototype.enlarge = function enlarge(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImgProcessor.Enlarge, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImgProcessorClient.prototype.convert = function convert(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImgProcessor.Convert, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImgProcessorClient.prototype.resize = function resize(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImgProcessor.Resize, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImgProcessorClient.prototype.restoreStretching = function restoreStretching(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImgProcessor.RestoreStretching, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImgProcessorClient.prototype.animate = function animate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImgProcessor.Animate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImgProcessorClient.prototype.colourize = function colourize(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImgProcessor.Colourize, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImgProcessorClient.prototype.enhanceDefinition = function enhanceDefinition(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImgProcessor.EnhanceDefinition, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ImgProcessorClient.prototype.removeBackground = function removeBackground(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ImgProcessor.RemoveBackground, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ImgProcessorClient = ImgProcessorClient;


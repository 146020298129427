import {
    CommonRequest,
    CompressRequest,
    ConvertRequest,
    ResizeRequest,
    CommonReply,
} from '../grpc/imgpro_pb';
import {
    ImgProcessorClient
} from '../grpc/imgpro_pb_service';
import { ACTION_TYPE } from '../models/usageModel';
import { ImgProcessorIntercepteInvoke } from "./interceptor";

//const host = "http://192.168.31.134:18888";
const host = "http://" + window.location.hostname +":"+ window.location.port;
class ImgProcessorSrv {
    client: ImgProcessorClient = new ImgProcessorClient(host);

    Compress(request: CompressRequest): Promise<CommonReply> {
        return ImgProcessorIntercepteInvoke(this.client.compress.bind(this.client), ACTION_TYPE.ACTION_TYPE_COMPRESSION, request);
    }

    Enlarge(request: CommonRequest): Promise<CommonReply> {
        return ImgProcessorIntercepteInvoke(this.client.enlarge.bind(this.client), ACTION_TYPE.ACTION_TYPE_ENLARGE_SIZE, request);
    }

    Convert(request: ConvertRequest): Promise<CommonReply> {
        return ImgProcessorIntercepteInvoke(this.client.convert.bind(this.client), ACTION_TYPE.ACTION_TYPE_CONVERT, request);
    }

    Resize(request: ResizeRequest): Promise<CommonReply> {
        return ImgProcessorIntercepteInvoke(this.client.resize.bind(this.client), ACTION_TYPE.ACTION_TYPE_RESIZE, request);
    }

    RestoreStretching(request: CommonRequest): Promise<CommonReply> {
        return ImgProcessorIntercepteInvoke(this.client.restoreStretching.bind(this.client), ACTION_TYPE.ACTION_TYPE_RESTORE_STRETCH, request);
    }

    Animate(request: CommonRequest): Promise<CommonReply> {
        return ImgProcessorIntercepteInvoke(this.client.animate.bind(this.client), ACTION_TYPE.ACTION_TYPE_ANIMATE, request);
    }

    Colourize(request: CommonRequest): Promise<CommonReply> {
        return ImgProcessorIntercepteInvoke(this.client.colourize.bind(this.client), ACTION_TYPE.ACTION_TYPE_COLOURIZE, request);
    }

    EnhanceDefinition(request: CommonRequest): Promise<CommonReply> {
        return ImgProcessorIntercepteInvoke(this.client.enhanceDefinition.bind(this.client), ACTION_TYPE.ACTION_TYPE_ENHANCE_DEFINITION, request);
    }

    RemoveBackground(request: CommonRequest): Promise<CommonReply> {
        return ImgProcessorIntercepteInvoke(this.client.removeBackground.bind(this.client), ACTION_TYPE.ACTION_TYPE_REMOVE_BACKGROUND, request);
    }
}

let imgprocessorSvc = new ImgProcessorSrv();
export default imgprocessorSvc;
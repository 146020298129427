import React from 'react';
import './toolItem.css';
import { Tool } from '../../../models/toolModel';

interface ToolItemProps{
    data: Tool;
}

export default class ToolItem extends React.Component<ToolItemProps, {}> {
    render() {
        return (
            <div className="tool-item" onClick={() => {
                window.location.href = this.props.data.url
            }}>
                <img className="tool-item-img" src={this.props.data.imgSrc} alt="" ></img>
                <div className="tool-item-title" >{this.props.data.title}</div>
                <div className="tool-item-describe" >{this.props.data.describe}</div>
            </div>
        );
    }
}
import React from 'react';
import './functionDemo.css';
import { FormattedMessage } from 'react-intl';
import { FunctionDemoData } from '../../models/functionDemoModel';
import BeforeAfterSlider from 'react-before-after-slider';


interface FunctionDemoProps {
    data: FunctionDemoData;
}

interface FunctionDemoState {
    width: number;
    height: number;
}

export default class FunctionDemo extends React.Component<FunctionDemoProps, FunctionDemoState> {
    setImageSizeFunc: any = null;
    constructor(props: FunctionDemoProps) {
        super(props);
        this.state = {
            width: this.width,
            height: this.height,
        };

        this.setImageSizeFunc = this.setImageSize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.setImageSizeFunc);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setImageSizeFunc);
    }

    setImageSize() {
        this.setState({
            width: this.width,
            height: this.height,
        });
    }
    get width(): number {
        return window.innerWidth < 800 ? window.innerWidth - 60 : 800;
    }

    get height(): number {
        return this.width * 480 / 800;
    }

    render() {
        return (
            <div className="function-demo">
                <div className="function-demo-content">
                    {!!this.props.data &&
                        <div className="function-demo-title"><FormattedMessage id="function-demo" /></div>
                    }
                    {!!this.props.data &&
                        <>
                            <BeforeAfterSlider
                                before={this.props.data.beforeImgSrc}
                                after={this.props.data.afterImgSrc}
                                width={this.state.width}
                                height={this.state.height}
                            />
                            <div className="function-demo-before-desc">{this.props.data.beforeImgdesc}</div>
                            <div className="function-demo-after-desc">{this.props.data.afterImgdesc}</div>
                        </>
                    }
                </div>
            </div>
        );
    }
}
let Intl_en: any = {
    "motto": "Smart and professional toolset for processing images in bulk!",
    "SignIn": "Sign In/Up",
    "compress": "COMPRESS IMAGE",
    "enlarge-size": "ENLARGE IMAGE",
    "convert": "CONVERT IMAGE",
    "resize": "RESIZE IMAGE",
    "restore-stretching": "RESTORE STRETCHING",
    "animate": "CARTOONIZE PHOTO",
    "colourize": "COLORIZE IMAGE",
    "enhance-definition": "ENHANCE RESOLUTION",
    "remove-background": "REMOVE BACKGROUND",
    "compress-describe": "Compress JPG, PNG and GIF images while saving space and maintaining quality.",
    "enlarge-size-describe": "Enlarge and upscale images without losing quality. Support enlarge by 200%.",
    "convert-describe": "Convert your JPG, PNG, GIF, TIFF and BMP image format in bulk with ease.",
    "resize-describe": "Easily resize your JPG, PNG, GIF, TIFF and BMP images in seconds.",
    "restore-stretching-describe": "Automatically recognize overstretched images and restore in bulk with ease.",
    "animate-describe": "Convert your photo and picture into cartoon effect in seconds.",
    "colourize-describe": "Colorize black and white pictures automatically with AI.",
    "enhance-definition-describe": "Enhance image resolution with ease. Make your image look better.",
    "remove-background-describe": "Remove the background from image automatically. Support batch process.",
    "clickOrDragToCompress": "Click or drag images to this area to compress",
    "compress-level": "Compression Level:",
    "compress-level-slight": "Best Quality",
    "compress-level-normal": "Balance",
    "compress-level-strong": "Best Compression",
    "compress-images": "Compress Images",
    "download-images": "Download Images",
    "compress-result-file-name": "compressed_images.zip",
    "backToHome": "Back Home",
    "msg_404": "Sorry, the page you visited does not exist.",
    "enlarge-result-file-name": "enlarged_images.zip",
    "enlarge-images": "Enlarge Images",
    "clickOrDragToEnlarge": "Click or drag images to this area to enlarge",
    "enhance-result-file-name": "enhanced_images.zip",
    "enhance-images": "Enhance Images",
    "clickOrDragToEnhance": "Click or drag images to this area to enhance",
    "convert-result-file-name": "converted_images.zip",
    "convert-images": "Convert Images",
    "clickOrDragToConvert": "Click or drag images to this area to convert images",
    "resize-result-file-name": "resized_images.zip",
    "resize-images": "Resize Images",
    "clickOrDragToResize": "Click or drag images to this area to resize images",
    "animate-result-file-name": "cartoonized_images.zip",
    "animate-images": "Cartoonize Images",
    "clickOrDragToAnimate": "Click or drag images to this area to cartoonize images",
    "colourize-result-file-name": "colorized_images.zip",
    "colourize-images": "Colorize Images",
    "clickOrDragToColourize": "Click or drag images to this area to colorize images",
    "remove-background-result-file-name": "foreground_images.zip",
    "remove-background-images": "Remove Background",
    "clickOrDragToRemoveBackground": "Click or drag images to this area to remove background",
    "restore-result-file-name": "restored_images.zip",
    "restore-images": "Restore Stretched Images",
    "clickOrDragToRestore": "Click or drag images to this area to restore stretched images",
    "width": "Width:",
    "height": "Height:",
    "convert-to": "Convert To:",
    "jpeg-format": "JPG/JPEG(*.jpg, *.jpeg)",
    "png-format": "PNG(*.png)",
    "gif-format": "GIF(*.gif)",
    "tiff-format": "TIFF(*.tif, *.tiff)",
    "bmp-format": "BMP(*.bmp)",
    "subscription-title": "Get more with Premium",
    "subscription-description": "Enjoy more professional functions, larger bluk size, faster processing speed and a web experience free of ads.",
    "subscription": "Get Premium",
    "instroduction-quality-title": "Perfect Quality",
    "instroduction-quality-description": "The best online image toolset for processing your images at the highest quality.",
    "instroduction-fast-title": "Lightning Fast",
    "instroduction-fast-description": "This cloud-hosted, highly scalable tool can process your images within seconds!",
    "instroduction-easytouse-title": "Easy to Use",
    "instroduction-easytouse-description": "Simply upload your image and click one button to finish your job. It's as easy as that!",
    "instroduction-anywhere-title": "Works Anywhere",
    "instroduction-anywhere-description": "ImgPro is browser-based (no software to install). It works on any platform (Windows, Linux, Mac).",
    "instroduction-safety-title": "Privacy Guaranteed",
    "instroduction-safety-description": "Your images are uploaded via a secure 256-bit encrypted TSL connection and deleted automatically.",
    "instroduction-free-title": "It's Free",
    "instroduction-free-description": "Most of our tools are free! There is no software to install, registrations, or watermarks.",
    "add-images": "Add More Images",
    "fqa": "FQA",
    "function-demo": "FUNCTION DEMO",
    "still-have-question": "Still have questions?",
    "compress-success-title": "Your IMAGES have been compressed",
    "animate-success-title": "Your IMAGES have been cartoonized!",
    "colourize-success-title": "Your IMAGES have been colorized!",
    "convert-success-title": "Your IMAGES have been converted!",
    "enhance-success-title": "Your IMAGES have been enhanced!",
    "enlarge-success-title": "Your IMAGES have been enlarged!",
    "remove-background-success-title": "Your IMAGES' background have been removed!",
    "resize-success-title": "Your IMAGES have been resized!",
    "restore-success-title": "Your IMAGES have been restored!",
    "thank-share": "Like it? Share it!",
    "thank-donate": "If ImgPro's IMAGE tool has helped you, you can help us too",
    "delete-data": "All uploaded images have been deleted.",
    "server-error-msg": "Sorry, something went wrong. Please try again later.",
    "go-back": "Go Back",
    "in-compressing": "Image Compressing...",
    "in-compressing-describe": "Everything is almost ready for you, please be patient.",
    "in-animating": "Image Cartoonizing...",
    "in-animating-describe": "Everything is almost ready for you, please be patient.",
    "in-colourizing": "Image Colorizing...",
    "in-colourizing-describe": "Everything is almost ready for you, please be patient.",
    "in-converting": "Image Converting...",
    "in-converting-describe": "Everything is almost ready for you, please be patient.",
    "in-enhancing": "Image Enhancing...",
    "in-enhancing-describe": "Everything is almost ready for you, please be patient.",
    "in-enlarging": "Image Enlarging...",
    "in-enlarging-describe": "Everything is almost ready for you, please be patient.",
    "in-removing-background": "Removing Images' Background...",
    "in-removing-background-describe": "Everything is almost ready for you, please be patient.",
    "in-resizing": "Image Resizing...",
    "in-resizing-describe": "Everything is almost ready for you, please be patient.",
    "in-restoring": "Image Restoring...",
    "in-restoring-describe": "Everything is almost ready for you, please be patient.",
    "jpg-or-png-or-bmp": "You can only upload JPG, PNG or BMP files!",
    "jpg-or-png-or-gif": "You can only upload JPG, PNG or GIF files!",
    "jpg-or-png-or-gif-or-tiff-or-bmp": "You can only upload JPG, PNG, GIF, TIFF or BMP files!",
    "small-then-8m": "Image file size must smaller than 8MB!",
    "small-then-2m": "Image file size must smaller than 2MB!",
    "download-failed-msg": "Download images failed!",
    "compress-before": "Before Compression: ",
    "compress-after": "After Compression: ",
    "enlarge-before": "Before Enlarge: ",
    "enlarge-after": "After Enlarge: ",
    "common-before": "Before",
    "common-after": "After",
    "fqa-1-question": "How it works?",
    "fqa-2-question": "Do my images remain on your servers?",
    "fqa-3-question": "How many formats are supported?",
    "fqa-4-question": "Can I use your services for my Business?",
    "fqa-1-anwser-1": "The Cartoonizer is powered by AI, and has been trained by deep neural networks on million images to learn how to automatically extract objects and cartoonize them.",
    "fqa-1-anwser-2": "The Colorizer is powered by AI, and has been trained by deep neural networks on million images to learn how to automatically extract objects and colorize them.",
    "fqa-1-anwser-3": "The Image Enhancer is powered by AI, and has been trained by deep neural networks on million images to learn how to automatically enhance images.",
    "fqa-1-anwser-4": "The Image Enlarger is powered by AI, and has been trained by deep neural networks on million images to learn how to automatically enlarge images without losing quality.",
    "fqa-1-anwser-5": "The Background Remover is powered by AI, and has been trained by deep neural networks on million images to learn how to automatically extract objects and clear the background.",
    "fqa-1-anwser-6": "The Image Restore Tool is powered by AI, and has been trained by deep neural networks on million images to learn how to automatically restore stretched images.",
    "fqa-2-anwser": "Your pictures belong to you only. We will never store them. The workflow is to upload your images, process the images and then return the image file results. We couldn't presist any image data!",
    "fqa-3-anwser-1": "Currently, we support the most common formats: JPG, PNG and BMP.",
    "fqa-3-anwser-2": "Currently, we support the most common formats: JPG, PNG and GIF.",
    "fqa-3-anwser-3": "Currently, we support the most common formats: JPG, PNG, GIF, TIFF and BMP.",
    "fqa-4-anwser": "Definitely.",
    "account": "Account",
    "upgrade": "Upgrade to Premium",
    "logout": "Log Out",
    "need-upload-file": "Please make sure your images have been uploaded successfully!",
    "uploading": "Uploading...",
    "client-invalid": "Client is invalid!",
    "login-to-agree": "By creating an account, I agree that I have read and accepted the",
    "tou": "Terms of Use",
    "and": "and",
    "privacy": "Privacy Policy",
    "other-logins": "Or log in with",
    "new-user": "New user?",
    "sign-in": "Sign in",
    "to-sign-in": "Sign in",
    "forgot-password": "Forgotten password?",
    "already-have-account": "Already have an account?",
    "sign-up": "Sign up",
    "to-sign-up": "Create an account",
    "input-name": "Name",
    "input-email": "Email",
    "input-name-or-email": "Enter your name or email",
    "input-password": "Password",
    "not-complete-input": "Please input necessary user information.",
    "username-email-format-wrong": "Name or email is invalid.",
    "username-format-wrong": "Name should contain at least 6 characters or numbers.",
    "email-format-wrong": "Email is not a valid email address.",
    "password-format-wrong": "Password should contain at least 8 characters and numbers.",
    "free": "Free",
    "US$0": "$0",
    "free-get-started": "Get Started",
    "free-features": "Free features include:",
    "limited-access-tools": "Limited access to ImgPro tools",
    "limited-process-count": "Limited image processing count",
    "limited-process-batch": "Limited image processing batch",
    "free-works-anywhere": "Works anywhere",
    "premium": "Premium",
    "US$9.9": "$9.9",
    "month": "month",
    "or": "or",
    "US$99.9": "$99.9",
    "year": "year",
    "free-trial": "Free Trial",
    "go-premium": "Go Premium",
    "premium-features": "Premium features include:",
    "full-access-tools": "Full access to ImgPro tools",
    "unlimited-process-count": "Unlimited image processing count",
    "maximum process-batch": "Maximum image processing batch",
    "no-ads": "No Ads",
    "current-support-in24h": "Custom support in 24h",
    "first-login-free-trial": "Login to free try all advanced features",
    "tool": "Tool",
    "availability": "Availability",
    "count-limit": "Limited Count/Day",
    "batch-limit": "Limited Batch",
    "unlimited": "Unlimited",
    "fqa-premium-1-question": "Can I try before I subscribe?",
    "fqa-premium-1-anwser": "Of course. A free user have a limited access to ImgPro tools. After firstly login, you could free try all advanced features. By upgrading to Premium, you would get access to all the tools without limit.",
    "fqa-premium-2-question": "What payment methods do you accept?",
    "fqa-premium-2-anwser": "Currently, we accept payments through the credit card, PayPal, Wechat, AliPay and others . If you need to use any other payment method, let us know.",
    "fqa-premium-3-question": "What if error occurs during subscribing?",
    "fqa-premium-3-anwser": "Please feel free to contact us. We will fix it in 24h.",
    "fqa-premium-4-question": "Can you invoice me?",
    "fqa-premium-4-anwser": "Sorry. So far, the Tax Invoicing is unavailable.",
    "suggest-premium-function-first-login-free-trial": "Premium-only feature, sign up to free try?",
    "suggest-useup-first-login-free-trial": "Today's quota for this feature has been used up, sign up to get more quota?",
    "suggest-premium-function-verify-email": "Premium-only feature, verify email to free try?",
    "suggest-useup-verify-email": "Today's quota for this feature has been used up, verify email to get more quota?",
    "suggest-premium-function-to-subscribe": "Premium-only feature, upgrade to Premium to utilize all the tools without limit?",
    "suggest-useup-to-subscribe": "Today's quota for this feature has been used up, upgrade to Premium to utilize all the tools without limit?",
    "suggest-useup-trial-to-subscribe": "Today's quota for this feature has been used up, upgrade to Premium to utilize all the tools without limit?",
    "to-sigin": "To Sign Up",
    "to-verify-email": "To Verify",
    "to-subscribe": "Get Premium",
    "to-giveup": "Not Sure",
    "profile": "Profile",
    "activity": "Activity",
    "billing": "Billing",
    "account-type": "Account Type",
    "free-account-type": "Free Account",
    "premium-account-type": "Premium Account",
    "free-account-tip": "Go Premium to enjoy more professional functions, larger bluk size, faster processing speed and a web experience free of ads.",
    "premium-account-tip": "Premium user would enjoy more professional functions, larger bluk size, faster processing speed and a web experience free of ads.",
    "account-info": "Account Infos",
    "account-username": "Username: ",
    "account-country": "Country: ",
    "account-country-unset": "Not Set",
    "modify-account-info": "Modify User Infos",
    "safety-settings": "Safety Settings",
    "safety-settings-email": "Email",
    "safety-settings-email-unset": "Not Set",
    "safety-settings-email-toset": "To Set",
    "safety-settings-email-unverify": "Unverified",
    "safety-settings-email-toverify": "To Verify",
    "safety-settings-email-verified": "Verified",
    "safety-settings-modify-email": "Change Email",
    "safety-settings-password": "Password",
    "safety-settings-modify-password": "Modify Password",
    "perferences-settings": "Perferences",
    "perferences-settings-tonotify": "Notification Settings",
    "perferences-settings-tonotify-tip": "Open notification to acquire the messages from ImgPro in the first place.",
    "open": "Open",
    "close": "Close",
    "successful-task": "Successful Task",
    "failed-task": "Failed Task",
    "action-date": "Date",
    "action-tool": "Tool",
    "action-batch": "File Batch",
    "action-status": "Status",
    "monthly-subscription": "Monthly Subscrition",
    "yearly-subscription": "Yearly Subscrition",
    "billing-type": "Billing Type",
    "money": "Money",
    "premium-duration": "Premium Duration",
    "login-other-place": "The user has signed in othe place, please resign.",
};
export default Intl_en;
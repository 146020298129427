let Intl_cn: any = {
    "motto": "您专业智能的图片批量处理工具箱！",
    "SignIn": "登录/注册",
    "compress": "无损压缩图片",
    "enlarge-size": "无损放大图片",
    "convert": "图片转格式",
    "resize": "图片改尺寸",
    "restore-stretching": "拉伸图像恢复",
    "animate": "动漫图像生成",
    "colourize": "黑白图像上色",
    "enhance-definition": "图像清晰度增强",
    "remove-background": "抠图去背景",
    "compress-describe": "不损失原图质量，极大层度地压缩JPG，PNG和GIF图片，并且可以批量压缩。",
    "enlarge-size-describe": "使用人工智能技术，将图像在长宽方向各放大两倍，并保持图像质量无损。",
    "convert-describe": "常见图片格式之间的相互转换，比如：JPG，PNG，GIF，TIFF，BMP等。",
    "resize-describe": "批量修改图片尺寸，以适用于更多不同的应用场景。",
    "restore-stretching-describe": "使用人工智能技术，自动识别过度拉伸的图像，将图像内容恢复成正常比例。",
    "animate-describe": "使用人工智能技术，为您量身定制可爱的二次元动漫形象，让您刷爆朋友圈。",
    "colourize-describe": "使用人工智能技术，智能为黑白老照片上色，给您的照片找回时尚感。",
    "enhance-definition-describe": "使用人工智能技术，智能快速去噪，优化图像纹理细节，使画面更加自然清晰。",
    "remove-background-describe": "智能识别图像中的人体轮廓，与背景进行分离，是您抠图的好帮手。",
    "clickOrDragToCompress": "点击或拖动图片文件到此区域开始压缩",
    "compress-level": "选定压缩级别：",
    "compress-level-slight": "最佳压缩",
    "compress-level-normal": "轻微有损",
    "compress-level-strong": "强力压缩",
    "compress-images": "压缩图片",
    "download-images": "下载图片",
    "compress-result-file-name": "压缩后图片.zip",
    "backToHome": "返回主页",
    "msg_404": "对不起，您访问的页面不存在。",
    "enlarge-result-file-name": "放大后图片.zip",
    "enlarge-images": "无损放大图片",
    "clickOrDragToEnlarge": "点击或拖动图片文件到此区域开始放大",
    "enhance-result-file-name": "增强后图片.zip",
    "enhance-images": "增强图片清晰度",
    "clickOrDragToEnhance": "点击或拖动图片文件到此区域开始图片增强",
    "convert-result-file-name": "新格式图片.zip",
    "convert-images": "转换图片格式",
    "clickOrDragToConvert": "点击或拖动图片文件到此区域开始转换图片格式",
    "resize-result-file-name": "新尺寸图片.zip",
    "resize-images": "修改图片尺寸",
    "clickOrDragToResize": "点击或拖动图片文件到此区域开始修改图片尺寸",
    "animate-result-file-name": "动画图片.zip",
    "animate-images": "图片动漫化",
    "clickOrDragToAnimate": "点击或拖动图片文件到此区域开始生成动漫图片",
    "colourize-result-file-name": "上色后图片.zip",
    "colourize-images": "图片上色",
    "clickOrDragToColourize": "点击或拖动图片文件到此区域开始为图片上色",
    "remove-background-result-file-name": "抠图后图片.zip",
    "remove-background-images": "去除图片背景",
    "clickOrDragToRemoveBackground": "点击或拖动图片文件到此区域开始去除图片背景",
    "restore-result-file-name": "恢复后图片.zip",
    "restore-images": "恢复变形图片",
    "clickOrDragToRestore": "点击或拖动图片文件到此区域开始恢复变形图片",
    "width": "宽度：",
    "height": "高度：",
    "convert-to": "转换成：",
    "jpeg-format": "JPG/JPEG(*.jpg, *.jpeg)",
    "png-format": "PNG(*.png)",
    "gif-format": "GIF(*.gif)",
    "tiff-format": "TIFF(*.tif, *.tiff)",
    "bmp-format": "BMP(*.bmp)",
    "subscription-title": "通过高级版享受更多功能",
    "subscription-description": "享受更多的专享功能、更大的批处理量、更快的处理速度、以及无广告的网页使用体验。",
    "subscription": "升级至高级账户",
    "instroduction-quality-title": "质量第一",
    "instroduction-quality-description": "提供高质量的图片处理服务，并会根据用户反馈进行持续优化提升。",
    "instroduction-fast-title": "快速稳定",
    "instroduction-fast-description": "服务部署在云端，可以持久地为您提供快速、可靠、稳定的服务。",
    "instroduction-easytouse-title": "使用方便",
    "instroduction-easytouse-description": "提供用户友好的使用体验，并会根据用户反馈进行持续优化。",
    "instroduction-anywhere-title": "设备支持",
    "instroduction-anywhere-description": "通过浏览器提供服务，无需安装，您可以在任何设备任何地点享受我们提供的优质服务。",
    "instroduction-safety-title": "隐私保障",
    "instroduction-safety-description": "您的图片通过TSL保证传输安全，网站仅仅处理图片，不会保存图片，您可以放心使用",
    "instroduction-free-title": "永久免费",
    "instroduction-free-description": "大部分服务永久免费，无需安装软件或注册，也不会添加水印。",
    "add-images": "添加更多图片",
    "fqa": "常见问题",
    "function-demo": "效果展示",
    "still-have-question": "有问题要反馈?",
    "compress-success-title": "您的图片已被成功压缩了",
    "animate-success-title": "已为您量身定制二次元图像！",
    "colourize-success-title": "已为您的图片重新配色！",
    "convert-success-title": "已为你生成新格式图片！",
    "enhance-success-title": "您的图片已通过AI进行了增强！",
    "enlarge-success-title": "已为您无损放大了图片！",
    "remove-background-success-title": "已为您移除了图片背景！",
    "resize-success-title": "已为您生成新尺寸的图片！",
    "restore-success-title": "已为您恢复变形图片！",
    "thank-share": "您觉得赞？不妨分享一下吧！",
    "thank-donate": "如果ImgPro工具帮到了您，您也可以通过捐款来帮助我们更好地完善和维护她",
    "delete-data": "您上传到网站的所有图片数据已被清除！",
    "server-error-msg": "非常抱歉，网络开小差啦，请您稍后重试。",
    "go-back": "返回",
    "in-compressing": "图片压缩中...",
    "in-compressing-describe": "正在为您压缩图片，一会就好，请您耐心等待。",
    "in-animating": "动漫图片生成中...",
    "in-animating-describe": "正在为您生成二次元图像，一会就好，请您耐心等待。",
    "in-colourizing": "彩色图片生成中...",
    "in-colourizing-describe": "正在为您生成彩色图像，一会就好，请您耐心等待。",
    "in-converting": "图片格式转换中...",
    "in-converting-describe": "正在为您转换图片格式，一会就好，请您耐心等待。",
    "in-enhancing": "图片增强中...",
    "in-enhancing-describe": "正在为您生成增强图片清晰度，一会就好，请您耐心等待。",
    "in-enlarging": "图片放大中...",
    "in-enlarging-describe": "正在为您无损放大图像，一会就好，请您耐心等待。",
    "in-removing-background": "图片去背景...",
    "in-removing-background-describe": "正在为您移除图片背景，一会就好，请您耐心等待。",
    "in-resizing": "新尺寸图片生成中...",
    "in-resizing-describe": "正在为您创建新尺寸图片，一会就好，请您耐心等待。",
    "in-restoring": "图片恢复中...",
    "in-restoring-describe": "正在为您恢复拉伸变形图像，一会就好，请您耐心等待。",
    "jpg-or-png-or-bmp": "抱歉，目前该功能仅支持PNG、JPG或BMP格式文件哦！",
    "jpg-or-png-or-gif": "抱歉，目前该功能仅支持PNG、JPG或GIF格式文件哦！",
    "jpg-or-png-or-gif-or-tiff-or-bmp": "抱歉，目前该功能仅支持PNG、JPG、GIF、TIFF或BMP格式文件哦！",
    "small-then-8m": "抱歉，目前该功能支持的图片文件不得超过8M哦！",
    "small-then-2m": "抱歉，目前该功能支持的图片文件不得超过2M哦！",
    "download-failed-msg": "抱歉，下载图片失败啦！",
    "compress-before": "压缩前尺寸： ",
    "compress-after": "压缩后尺寸： ",
    "enlarge-before": "放大前尺寸： ",
    "enlarge-after": "放大后尺寸： ",
    "common-before": "处理前",
    "common-after": "处理后",
    "fqa-1-question": "这个软件是什么原理？",
    "fqa-2-question": "我上传的图片在服务器上会有备份吗？",
    "fqa-3-question": "这个工具支持哪些格式的图片？",
    "fqa-4-question": "我能将这个工具用于商业用途吗？",
    "fqa-1-anwser-1": "图片动漫化工具使用人工智能实现，用到了上百万张图片来训练深度神经网络模型，以教会它如何自动识别人像和生成二次元人像。",
    "fqa-1-anwser-2": "图片上色工具使用人工智能实现，用到了上百万张图片来训练深度神经网络模型，以教会它如何自动识别物体以及给物体上色。",
    "fqa-1-anwser-3": "图片增强工具使用人工智能实现，用到了上百万张图片来训练深度神经网络模型，以教会它如何自动增强图像的清晰度。",
    "fqa-1-anwser-4": "图片无损放大工具使用人工智能实现，用到了上百万张图片来训练深度神经网络模型，以教会它如何在不损失图像质量的前提下自动放大图像。",
    "fqa-1-anwser-5": "图片去背景工具使用人工智能实现，用到了上百万张图片来训练深度神经网络模型，以教会它如何自动识别人像和去除人像背景。",
    "fqa-1-anwser-6": "图片恢复工具使用人工智能实现，用到了上百万张图片来训练深度神经网络模型，以教会它如何自动分析和恢复拉伸变形的图像。",
    "fqa-2-anwser": "您的图片只属于您自己，我们不会进行备份。我们的处理流程是：用户上传图片，服务器处理图片，返回图片文件给用户，我们不会存储用户的任何图片数据！",
    "fqa-3-anwser-1": "当前我们支持最常用的图片格式：JPG、PNG以及BMP。",
    "fqa-3-anwser-2": "当前我们支持最常用的图片格式：JPG、PNG以及GIF。",
    "fqa-3-anwser-3": "当前我们支持最常用的图片格式：JPG、PNG、GIF、TIFF以及BMP。",
    "fqa-4-anwser": "可以的。",
    "account": "账户信息",
    "upgrade": "升级至高级账户",
    "logout": "退出",
    "need-upload-file": "操作前，请先成功上传图片！",
    "uploading": "正在上传文件...",
    "client-invalid": "非法客户端访问",
    "login-to-agree": "登录即表示您已阅读并同意",
    "tou": "用户协议",
    "and": "和",
    "privacy": "隐私条款",
    "other-logins": "其他方式登录",
    "new-user": "新用户？",
    "sign-in": "登录",
    "to-sign-in": "去登录",
    "forgot-password": "忘记密码？",
    "already-have-account": "已有账号？",
    "sign-up": "注册",
    "to-sign-up": "去注册",
    "input-name": "请输入用户名",
    "input-email": "请输入邮箱",
    "input-name-or-email": "请输入用户名或邮箱",
    "input-password": "请输入密码",
    "not-complete-input": "请您输入必要的用户信息！",
    "username-email-format-wrong": "用户名或邮箱无效！",
    "username-format-wrong": "用户名应以字母开头，至少包含5个字母、数字、_或.！",
    "email-format-wrong": "输入的邮箱无效！",
    "password-format-wrong": "密码应至少包含8个字母和数字！",
    "free": "免费版",
    "US$0": "￥0",
    "free-get-started": "开始体验",
    "free-features": "免费版功能包括：",
    "limited-access-tools": "使用ImgPro提供的部分工具",
    "limited-process-count": "有限的图片处理数量",
    "limited-process-batch": "有限的批处理数量",
    "free-works-anywhere": "跨平台使用体验",
    "premium": "高级账户",
    "US$9.9": "￥66",
    "month": "月",
    "or": "或",
    "US$99.9": "￥666",
    "year": "年",
    "free-trial": "免费体验",
    "go-premium": "开通高级账户",
    "premium-features": "高级版功能包括：",
    "full-access-tools": "使用ImgPro提供的所有工具",
    "unlimited-process-count": "图片处理数量没有限制",
    "maximum process-batch": "最大的批处理数量",
    "no-ads": "免广告使用体验",
    "current-support-in24h": "24内客户支持服务",
    "first-login-free-trial": "首次登录可免费试用高级功能",
    "tool": "工具",
    "availability": "可用性",
    "count-limit": "数量限制/天",
    "batch-limit": "批处理量限制",
    "unlimited": "不限制",
    "fqa-premium-1-question": "订购之前，我可以试用吗？",
    "fqa-premium-1-anwser": "当然了。免费账户可以有限制地使用ImgPro的部分工具；首次注册后，您还可以免费获取高级功能的使用额度；但升级到高级账户后，您将无限制地享受所有的高级功能。",
    "fqa-premium-2-question": "你们接受哪些支付方式？",
    "fqa-premium-2-anwser": "目前我们接受信用卡、PayPal、微信、支付宝等付款方式。如果你需要使用其他支付方式，请告诉我们。",
    "fqa-premium-3-question": "操作中，出现错误怎么办？",
    "fqa-premium-3-anwser": "如果操作过程中出现错误，请及时联系我们，我们会在24内为您解决问题。",
    "fqa-premium-4-question": "能开发票吗？",
    "fqa-premium-4-anwser": "抱歉，目前我们暂不支持税务发票。",
    "suggest-premium-function-first-login-free-trial": "该功能为订阅用户专享，首次登录可免费试用！",
    "suggest-useup-first-login-free-trial": "您已用完该功能今日使用额度，首次登录可免费获取更多试用额度！",
    "suggest-premium-function-verify-email": "该功能为订阅用户专享，完成邮箱验证可免费试用！",
    "suggest-useup-verify-email": "您已用完该功能今日使用额度，完成邮箱验证可免费获取更多试用额度！",
    "suggest-premium-function-to-subscribe": "该功能为订阅用户专享，完成订阅后可无限制地使用！",
    "suggest-useup-to-subscribe": "您已用完该功能今日使用额度，完成订阅后可无限制地使用！",
    "suggest-useup-trial-to-subscribe": "您已用完该功能试用额度，完成订阅后可无限制地使用！",
    "to-sigin": "去登录",
    "to-verify-email": "去验证邮箱",
    "to-subscribe": "去订阅",
    "to-giveup": "再看看",
    "profile": "个人资料",
    "activity": "历史操作",
    "billing": "账单信息",
    "account-type": "账户类型",
    "free-account-type": "免费账户",
    "premium-account-type": "高级账户",
    "free-account-tip": "开通高级账户可享受更多的专享功能、更大的批处理量、更快的处理速度、以及无广告的网页使用体验。",
    "premium-account-tip": "高级账户可享受更多的专享功能、更大的批处理量、更快的处理速度、以及无广告的网页使用体验。",
    "account-info": "账户信息",
    "account-username": "用户名：",
    "account-country": "国家：",
    "account-country-unset": "未设置",
    "modify-account-info": "修改信息",
    "safety-settings": "安全设置",
    "safety-settings-email": "邮箱",
    "safety-settings-email-unset": "未设置",
    "safety-settings-email-toset": "去设置",
    "safety-settings-email-unverify": "未验证",
    "safety-settings-email-toverify": "去验证",
    "safety-settings-email-verified": "已验证",
    "safety-settings-modify-email": "修改邮箱",
    "safety-settings-password": "密码",
    "safety-settings-modify-password": "修改密码",
    "perferences-settings": "偏好设置",
    "perferences-settings-tonotify": "提醒通知",
    "perferences-settings-tonotify-tip": "开启后可第一时间获取ImgPro的最新通知！",
    "open": "开启",
    "close": "关闭",
    "successful-task": "成功",
    "failed-task": "失败",
    "action-date": "日期",
    "action-tool": "工具",
    "action-batch": "文件数量",
    "action-status": "状态",
    "monthly-subscription": "按月订阅",
    "yearly-subscription": "按年订阅",
    "billing-type": "订阅类型",
    "money": "款额",
    "premium-duration": "订阅时间",
    "login-other-place": "该用户已在地方登录，请重新登录！",
};
export default Intl_cn;
import React from 'react';
import CommonHeader from '../../components/header/header';
import './privacy.css';
import { injectIntl } from 'react-intl';
import { Footer } from 'antd/lib/layout/layout';


class Privacy extends React.Component<any, {}> {
    render() {
        return (
            <div>
                <CommonHeader></CommonHeader>
                <div>privacy</div>
                <Footer style={{ textAlign: 'center' }}>ImgPro ©2021~2026 Created by BarretX</Footer>
            </div>
        );
    }
}

export default injectIntl(Privacy);
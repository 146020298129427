import { Button, Card, Col, Menu, Row, Switch, Table, Tag } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import React from 'react';
import { AiOutlineFieldNumber, AiOutlineFieldTime, AiOutlineTag, AiOutlineTool } from 'react-icons/ai';
import { BsFillAwardFill } from 'react-icons/bs';
import { FaHistory, FaMoneyCheck, FaSketch, FaUserCircle } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import { FormattedMessage, injectIntl } from 'react-intl';
import CommonHeader from '../../../components/header/header';
import { BooleanType, SubscribeType, UserType, VerifyType } from '../../../grpc/authmanager_pb';
import { BillingAction, UserAction } from '../../../models/activityModel';
import userInfoSrv from '../../../services/userInfoSrv';
import './user-info.css';

class Sider extends React.Component<any, any> {
    state = {
        selectedKey: "profile"
    };

    handleClick = (e: any) => {
        this.setState({ selectedKey: e.key });
    };

    render() {
        return (
            <Row className="user-info-layout">
                <Col flex="200px">
                    <Menu onClick={this.handleClick}
                        style={{ width: 200, minHeight: '800px', fontSize: '14px', fontWeight: 'bold' }}
                        defaultSelectedKeys={['profile']}
                        mode="inline"
                    >
                        <Menu.Item key="profile" icon={<FaUserCircle />}><FormattedMessage id="profile" /></Menu.Item>
                        <Menu.Item key="activity" icon={<FaHistory />}><FormattedMessage id="activity" /></Menu.Item>
                        <Menu.Item key="billing" icon={<FaSketch />}><FormattedMessage id="billing" /></Menu.Item>
                    </Menu>
                </Col>
                <Col flex="auto">
                    {this.state.selectedKey === "profile" && this.renderProfile()}
                    {this.state.selectedKey === "activity" && this.renderActivity()}
                    {this.state.selectedKey === "billing" && this.renderBilling()}
                </Col>
            </Row>
        );
    }

    renderProfile() {
        const intl = this.props.intl;
        let user = userInfoSrv.GetUser();
        return (
            <div className="user-info-content">
                <Card title={<strong><FormattedMessage id="account-type" /></strong>} className="user-info-card">
                    {user?.user_type === UserType.NORMAL &&
                        <>
                            <Tag color="#2db7f5"><FormattedMessage id="free-account-type" /></Tag>
                            <div className="tip-text"><FormattedMessage id="free-account-tip" /></div>
                            <Button danger ghost><FormattedMessage id="go-premium" /></Button>
                        </>
                    }
                    {user?.user_type === UserType.PREMIUM &&
                        <>
                            <Tag color="#87d068"><FormattedMessage id="premium-account-type" /></Tag>
                            <div className="tip-text"><FormattedMessage id="premium-account-tip" /></div>
                        </>
                    }
                </Card>
                <Card title={<strong><FormattedMessage id="account-info" /></strong>} className="user-info-card">
                    <div className="item-text"><FormattedMessage id="account-username" />{user?.username}</div>
                    <div className="item-text"><FormattedMessage id="account-country" />{(!user?.country || user?.country === '') ? intl.formatMessage({ id: 'account-country-unset' }) : user?.country}</div>
                    <Button danger ghost><FormattedMessage id="modify-account-info" /></Button>
                </Card>
                <Card title={<strong><FormattedMessage id="safety-settings" /></strong>} className="user-info-card">
                    <div className="item-text">
                        <strong><FormattedMessage id="safety-settings-email" /></strong>&nbsp;&nbsp;{user?.email}&nbsp;
                        {!user?.email &&
                            <>
                                <Tag color="red"><FormattedMessage id="safety-settings-email-unset" /></Tag>
                                <Button type="primary" ghost><FormattedMessage id="safety-settings-email-toset" /></Button>
                            </>
                        }
                        {!!user?.email && (!user.verify_at || user?.verify_type !== VerifyType.VERIFY_EMAIL) &&
                            <>
                                <Tag color="red"><FormattedMessage id="safety-settings-email-unverify" /></Tag>
                                <Button type="primary" ghost><FormattedMessage id="safety-settings-email-toverify" /></Button>&nbsp;&nbsp;
                                <Button danger ghost><FormattedMessage id="safety-settings-modify-email" /></Button>
                            </>
                        }
                        {!!user?.email && !!user.verify_at && user?.verify_type === VerifyType.VERIFY_EMAIL &&
                            <>
                                <Tag color="green"><FormattedMessage id="safety-settings-email-verified" /></Tag>
                                <Button danger ghost><FormattedMessage id="safety-settings-modify-email" /></Button>
                            </>
                        }
                    </div>
                    <div className="item-text">
                        <strong><FormattedMessage id="safety-settings-password" /></strong>
                        &nbsp;&nbsp;*********&nbsp;&nbsp;
                        <Button danger ghost><FormattedMessage id="safety-settings-modify-password" /></Button>
                    </div>
                </Card>
                <Card title={<strong><FormattedMessage id="perferences-settings" /></strong>} className="user-info-card">
                    <div>
                        <FormattedMessage id="perferences-settings-tonotify" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Switch checkedChildren={<FormattedMessage id="open" />} unCheckedChildren={<FormattedMessage id="close" />} checked={user?.to_notify === BooleanType.ENABLE} />
                    </div>
                    <div className="tip-text"><FormattedMessage id="perferences-settings-tonotify-tip" /></div>
                </Card>
            </div>
        );
    }

    activityData: Array<UserAction> = [
        {
            key: "1",
            created_at: new Date(),
            action: 0,
            batch: 8,
            is_succeed: true,
        },
        {
            key: "2",
            created_at: new Date(),
            action: 4,
            batch: 12,
            is_succeed: true,
        },
        {
            key: "3",
            created_at: new Date(),
            action: 8,
            batch: 1,
            is_succeed: false,
        },
    ];

    activityColumns = [
        {
            title: <div className='user-table-title-item'><AiOutlineFieldTime />&nbsp;<FormattedMessage id="action-date" /></div>,
            dataIndex: 'created_at',
            key: 'date',
            render: (created_at: Date) => <div>{created_at.toString()}</div>,
        },
        {
            title: <div className='user-table-title-item'><AiOutlineTool />&nbsp;<FormattedMessage id="action-tool" /></div>,
            dataIndex: 'action',
            key: 'tool',
            render: (action: number) =>
                <Tag color='blue'>
                    {this.getActionTypName(action)}
                </Tag>,
        },
        {
            title: <div className='user-table-title-item'><AiOutlineFieldNumber />&nbsp;<FormattedMessage id="action-batch" /></div>,
            dataIndex: 'batch',
            key: 'batch',
        },
        {
            title: <div className='user-table-title-item'><AiOutlineTag />&nbsp;<FormattedMessage id="action-status" /></div>,
            dataIndex: 'is_succeed',
            key: 'status',
            render: (is_succeed: boolean) => (
                <span>
                    <Tag color={is_succeed ? "green" : "red"}>
                        {is_succeed ?
                            <FormattedMessage id="successful-task" /> :
                            <FormattedMessage id="failed-task" />
                        }
                    </Tag>
                </span>
            ),
        },
    ];

    renderActivity() {
        return (
            <div className="user-info-content">
                <Table
                    columns={this.activityColumns}
                    pagination={{ position: ['bottomCenter'] }}
                    dataSource={this.activityData}
                />
            </div>
        );
    }

    billingData: Array<BillingAction> = [
        {
            key: '1',
            created_at: new Date(),
            pay_type: 0,
            pay_count: 66,
            subscribe_type: SubscribeType.MONTHLY,
            subscribe_from: 14566256378951334,
            subscribe_to: 14566256978951339,
        },
        {
            key: '2',
            created_at: new Date(),
            pay_type: 1,
            pay_count: 666,
            subscribe_type: SubscribeType.YEARLY,
            subscribe_from: 14566256378951334,
            subscribe_to: 14566256978951339,
        },
        {
            key: '3',
            created_at: new Date(),
            pay_type: 0,
            pay_count: 60,
            subscribe_type: SubscribeType.MONTHLY,
            subscribe_from: 14566256378951334,
            subscribe_to: 14566256978951339,
        },
    ];

    billingColumns = [
        {
            title: <div className='user-table-title-item'><AiOutlineFieldTime />&nbsp;<FormattedMessage id="action-date" /></div>,
            key: 'date',
            dataIndex: 'created_at',
            render: (created_at: Date) => <div>{created_at.toString()}</div>,
        },
        {
            title: <div className='user-table-title-item'><BsFillAwardFill />&nbsp;<FormattedMessage id="billing-type" /></div>,
            key: 'type',
            dataIndex: 'pay_type',
            render: (pay_type: number) =>
                <Tag color='blue'>
                    {this.getPayTypeName(pay_type)}
                </Tag>,
        },
        {
            title: <div className='user-table-title-item'><FaMoneyCheck />&nbsp;<FormattedMessage id="money" /></div>,
            key: 'money',
            dataIndex: 'pay_count',
        },
        {
            title: <div className='user-table-title-item'><MdDateRange />&nbsp;<FormattedMessage id="premium-duration" /></div>,
            key: 'duration',
            dataIndex: 'subscribe_from',
            render: (subscribe_from: number, record: BillingAction) =>
                <div>
                    {record.subscribe_from}~{record.subscribe_to}
                </div>,
        },
    ];

    renderBilling() {
        return (
            <div className="user-info-content">
                <Table
                    columns={this.billingColumns}
                    pagination={{ position: ['bottomCenter'] }}
                    dataSource={this.billingData}
                />
            </div>
        );
    }

    getActionTypName = (action_type: number) => {
        const intl = this.props.intl;
        const action_names = [
            intl.formatMessage({ id: 'compress' }),
            intl.formatMessage({ id: 'convert' }),
            intl.formatMessage({ id: 'resize' }),
            intl.formatMessage({ id: 'animate' }),
            intl.formatMessage({ id: 'colourize' }),
            intl.formatMessage({ id: 'enhance-definition' }),
            intl.formatMessage({ id: 'enlarge-size' }),
            intl.formatMessage({ id: 'remove-background' }),
            intl.formatMessage({ id: 'restore-stretching' }),
        ];
        return action_names[action_type];
    };

    getPayTypeName = (pay_type: number) => {
        const intl = this.props.intl;
        const pay_names = [
            intl.formatMessage({ id: 'monthly-subscription' }),
            intl.formatMessage({ id: 'yearly-subscription' }),
        ];
        return pay_names[pay_type];
    }
}

let SiderWrapper = injectIntl(Sider);


class UserInfo extends React.Component<any, any> {
    render() {
        return (
            <div>
                <CommonHeader></CommonHeader>
                <div className="user-info">
                    <SiderWrapper></SiderWrapper>
                </div>
                <Footer className="user-footer">ImgPro ©2021~2026 Created by BarretX</Footer>
            </div>
        );
    }
}
export default injectIntl(UserInfo);
import React from 'react';
import './fqaItem.css';
import { RiQuestionnaireFill,  RiQuestionAnswerFill} from "react-icons/ri";
import { FQAItemData } from '../../../models/fqaModel';

interface FQAItemProps{
    data: FQAItemData;
}

export default class FQAItem extends React.Component<FQAItemProps, {}> {
    render() {
        return (
            <div className="fqa-item">
                <div className="fqa-item-question" ><RiQuestionnaireFill/>&nbsp;&nbsp;{this.props.data.question}</div>
                <div className="fqa-item-answer" ><RiQuestionAnswerFill/>&nbsp;&nbsp;{this.props.data.answer}</div>
            </div>
        );
    }
}
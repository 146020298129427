import { SUGGEST_TYPE, USER_TYPE } from "../models/usageModel";
import userInfoSrv from "./userInfoSrv";
import EventEmitter from "events";

class SubscribeService {
    SuggestSubscribe(isVipFunction: boolean) {
        let user_type = userInfoSrv.GetUserType();
        switch (user_type) {
            case USER_TYPE.UNREGISTERED_USER:
                if (isVipFunction) {
                    // suggest to subscribe
                    this.showSuggestModal(SUGGEST_TYPE.SUGGEST_TYPE_PREMIUM_FIRST_LOGIN_TO_TRIAL);
                } else {
                    // suggest to free trial
                    this.showSuggestModal(SUGGEST_TYPE.SUGGEST_TYPE_USEUP_FIRST_LOGIN_TO_TRIAL);
                }
                return;
            case USER_TYPE.UNVERIFIED_USER:
                if (isVipFunction) {
                    // suggest to verify to free trial
                    this.showSuggestModal(SUGGEST_TYPE.SUGGEST_TYPE_PREMIUM_NEED_VERIFY_EMAIL);
                } else {
                    // suggest to verify to free trial
                    this.showSuggestModal(SUGGEST_TYPE.SUGGEST_TYPE_USEUP_NEED_VERIFY_EMAIL);
                }
                return;
            case USER_TYPE.REGISTERED_USER:
                if (isVipFunction) {
                    // suggest to subscribe
                    this.showSuggestModal(SUGGEST_TYPE.SUGGEST_TYPE_PREMIUM_NEED_SUBSCRIBE);
                } else {
                    // suggest to subscribe
                    this.showSuggestModal(SUGGEST_TYPE.SUGGEST_TYPE_USEUP_NEED_SUBSCRIBE);
                }
                return;
            case USER_TYPE.FREE_TRIAL_USER:
                // suggest to subscribe
                this.showSuggestModal(SUGGEST_TYPE.SUGGEST_TYPE_TRIAL_USEUP_NEED_SUBSCRIBE);
                return;
            case USER_TYPE.PREMIUM_USER:
            case USER_TYPE.INVALID_USER:
            default:
                return;
        }
    }

    SuggestResign(){
        this.showSuggestModal(SUGGEST_TYPE.SUGGEST_TYPE_NEED_RESIGN);
    }

    _suggestEvent: EventEmitter = new EventEmitter();
    subscribeSuggestEvent(callback: any) {
        this._suggestEvent.addListener("suggest", callback);
    }

    showSuggestModal(modal_type: number) {
        this._suggestEvent.emit("suggest", modal_type);
    }
}

let subscribeSrv = new SubscribeService();
export default subscribeSrv;